// src/components/LeadForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';

const LeadForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    options: '',
  });
  const [message, setMessage] = useState('');
  const [submitted, setSubmitted] = useState(false); // Track form submission status

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.name || !formData.email || !formData.phone || !formData.options) {
      setMessage('All fields are required.');
      return;
    }

    // Map formData to template parameters expected by EmailJS
    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      options: formData.options,
    };

    try {
      // Sending the form data via EmailJS
      await emailjs.send(
        'service_d41315s', // Your EmailJS Service ID
        'template_ml7sfur', // Your EmailJS Template ID
        templateParams,
        'vL8w8y040x5rEcv7u' // Your EmailJS User ID
      );
      setMessage('Erfolgreich abgeschickt. Wir melden uns bei Ihnen!');
      setSubmitted(true); // Set submitted to true on success
    } catch (error) {
      console.error('Error sending email:', error);
      setMessage('Da ist etwas schief gelaufen. Probieren Sie es später noch einmal.');
    }
  };

  return (
    <div className="lead-form">
      {submitted ? ( // Conditionally render message if form is submitted
        <p>{message}</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <input type="text" name="name" placeholder="Name" required value={formData.name} onChange={handleChange} />
            <input type="email" name="email" placeholder="E-Mail" required value={formData.email} onChange={handleChange} />
            <input type="tel" name="phone" placeholder="Telefonnummer" required value={formData.phone} onChange={handleChange} />
            <select name="options" required value={formData.options} onChange={handleChange}>
              <option value="">Bitte wählen</option>
              <option value="MFH-Verwaltung">Ich suche eine Verwaltung für mein MFH</option>
              <option value="WEG-Verwaltung">Ich suche eine WEG-Verwaltung</option>
              <option value="Mieter">Ich bin ein potenzieller Mieter und suche eine Wohnung</option>
            </select>
            <button type="submit">Senden</button>
          </div>
        </form>
      )}
      {!submitted && (
        <small>
          Die Sicherheit Ihrer Daten ist uns sehr wichtig. Mit dem Klick auf "Senden" stimmen Sie unseren
          <a href="/agb" target='_blank'> Allgemeinen Nutzungsbedingungen </a> und unserer 
          <a href="/datenschutz" target="_blank"> Datenschutzrichtlinie </a> zu.
        </small>
      )}
    </div>
  );
};

export default LeadForm;