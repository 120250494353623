import React, { useState } from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const steps = [
  { id: 1, text: 'Gemeinsam mit Ihnen sammeln wir alle Informationen rund um Ihr Mehrfamilienhaus.', image: '/checklist.png' },
  { id: 2, text: 'Dann analysieren wir die wirtschaftliche Situation und den Cashflow Ihres Objektes.', image: '/chart.png' },
  { id: 3, text: 'Wir informieren Mieter, Dienstleister, Versorger, etc. als zentraler Ansprechpartner.', image: '/service.png' },
  { id: 4, text: 'Wir binden existierende Bankkonten an unsere Verwaltung an oder erstellen neue.', image: '/bank.png' },
  { id: 5, text: 'Willkommen bei hamba! Ab jetzt kümmern wir uns um alles und informieren Sie monatlich.', image: '/celebration.png' },
  { id: 6, text: 'Wir vermieten nur an die besten Mieter. Jeder potenzielle Mieter wird intensiv geprüft.', image: '/transparency.png' },
  { id: 7, text: 'Und sorgen für korrekte und pünktliche Zahlungsströme.', image: '/calendar.png' },
  { id: 8, text: 'Wir erledigen die jährlichen Abrechnungen pünktlich, korrekt und nachvollziehbar.', image: '/money.png' },
  { id: 9, text: 'Und sind immer als Ansprechpartner für Sie und Ihre Mieter vor Ort.', image: '/person.png' },
];

function HowItWorks() {
  const [currentStep, setCurrentStep] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleNext = () => {
    if (isAnimating || currentStep === steps.length - 1) return; // Disable click on the last step
    setIsAnimating(true);
    setCurrentStep((prevStep) => (prevStep + 1) % steps.length);
  };

  const handlePrev = () => {
    if (isAnimating || currentStep === 0) return; // Disable click on the first step
    setIsAnimating(true);
    setCurrentStep((prevStep) => (prevStep === 0 ? steps.length - 1 : prevStep - 1));
  };

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <div className="how-it-works-container">
      <h1 className="how-it-works-title">Wie es bei uns funktioniert</h1>
      <h3 className="how-it-works-subtitle">
        <a>Reibungslose</a> Verwaltungsübernahme.
      </h3>
      <div className="how-it-works-carousel">
        <button
          onClick={handlePrev}
          className={`how-it-works-nav left ${currentStep === 0 ? 'disabled' : ''}`}
          disabled={currentStep === 0} // Prevent click functionality
        >
          <FaChevronLeft />
        </button>
        <div
          className={`how-it-works-step-content ${isAnimating ? 'animate' : ''}`}
          onAnimationEnd={handleAnimationEnd}
        >
          <img
            src={steps[currentStep].image}
            alt={`Step ${currentStep + 1}`}
            className="how-it-works-image"
          />
          <p className="how-it-works-text">{steps[currentStep].text}</p>
        </div>
        <button
          onClick={handleNext}
          className={`how-it-works-nav right ${currentStep === steps.length - 1 ? 'disabled' : ''}`}
          disabled={currentStep === steps.length - 1} // Prevent click functionality
        >
          <FaChevronRight />
        </button>
      </div>
      <div className="how-it-works-indicators">
        {steps.map((_, index) => (
          <span
            key={index}
            className={`how-it-works-indicator ${index === currentStep ? 'active' : ''}`}
            onClick={() => setCurrentStep(index)}
          >
            {index + 1}
          </span>
        ))}
      </div>
    </div>
  );
}

export default HowItWorks;