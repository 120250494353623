import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';

const Navbar = () => {
  const [showStickyNavbar, setShowStickyNavbar] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const originalNavbarHeight = document.querySelector('.navbar')?.offsetHeight || 0;

      if (window.scrollY > originalNavbarHeight) {
        setShowStickyNavbar(true);
      } else {
        setShowStickyNavbar(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  return (
    <>
      {/* Desktop Navbar */}
      <div className="navbar-wrapper">
        <nav className="navbar">
          <div className="navbar-container">
            <Link to="/">
              <div className="navbar-logo">
                <img src="/hamba.png" alt="hamba - Immobilienverwaltung kann so einfach sein" />
              </div>
            </Link>
            <ul className="navbar-links">
              <li><Link to="/verwaltung">Verwaltung</Link></li>
              {/*
              <li><Link to="/institutional">Institutional</Link></li>
              */}
              <li><Link to="/blog">Blog</Link></li>
              <li><Link to="/kontakt">Kontakt</Link></li>
            </ul>
            <Link to="/standort">
              <button className="navbar-btn">Kostenloses Angebot</button>
            </Link>
          </div>
        </nav>
      </div>

      {/* Sticky Navbar */}
      {showStickyNavbar && (
        <div className="sticky-navbar">
          <nav className="navbar-stick">
            <div className="navbar-container">
              <Link to="/">
                <div className="navbar-logo">
                <img src="/hamba.png" alt="hamba - Immobilienverwaltung kann so einfach sein" />
                </div>
              </Link>
              <ul className="navbar-links">
                <li><Link to="/verwaltung">Verwaltung</Link></li>
                {/*
                <li><Link to="/institutional">Institutional</Link></li>
                */}
                <li><Link to="/blog">Blog</Link></li>
                <li><Link to="/kontakt">Kontakt</Link></li>
              </ul>
              <Link to="/standort">
                <button className="navbar-btn">Kostenloses Angebot</button>
              </Link>
            </div>
          </nav>
        </div>
      )}

      {/* Mobile Navbar */}
      <div className="mobile-navbar">
        <div className="mobile-navbar-header">
          <Link to="/">
            <div className="navbar-logo">
              <img src="/hamba.png" alt="hamba Logo" />
            </div>
          </Link>
          <button onClick={toggleMobileMenu} className="mobile-navbar-toggle">
            {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>

        {/* Overlay Menu */}
        {isMobileMenuOpen && (
          <div className="mobile-navbar-overlay">
            {/* Close Icon */}
            <button className="mobile-navbar-close" onClick={toggleMobileMenu}>
              <FaTimes />
            </button>
            <ul className="mobile-navbar-links">
              <li><Link to="/verwaltung" onClick={toggleMobileMenu}>Verwaltung</Link></li>
              {/*
              <li><Link to="/institutional" onClick={toggleMobileMenu}>Institutional</Link></li>
              */}
              <li><Link to="/blog" onClick={toggleMobileMenu}>Blog</Link></li>
              <li><Link to="/kontakt" onClick={toggleMobileMenu}>Kontakt</Link></li>
            </ul>
            <Link to="/standort" onClick={toggleMobileMenu}>
              <button className="mobile-navbar-btn">Kostenloses Angebot</button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;