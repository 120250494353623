// src/components/Form.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Link, useNavigate } from 'react-router-dom';

const Form = () => {
    const [city, setCity] = useState('');
    const [step, setStep] = useState(1);
    const totalSteps = 6;
    const [message, setMessage] = useState(''); // Display message for submission
    const navigate = useNavigate(); // Initialize navigate

    const [formData, setFormData] = useState({
        option1: '',
        option2: '',
        number: '',
        option3: '',
        postalCode: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: ''
    });

    const [errors, setErrors] = useState({});
    const [displayNumber, setDisplayNumber] = useState('');

    const handleChange = (e) => {
        const { name, value, type } = e.target;
    
        // Only handle numeric input for the "number" field
        if (name === 'number') {
            const numericValue = value.replace(/\D/g, ''); // Remove non-numeric characters
            setFormData({
                ...formData,
                [name]: numericValue,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    
        if (type === 'radio') {
            document.querySelectorAll(`.hv-radio[name=${name}]`).forEach(label => {
                label.classList.remove('checked');
            });
            e.target.parentNode.classList.add('checked');
        }
    };
    const validateStep = () => {
        let valid = true;
        let newErrors = {};

        switch (step) {
            case 1:
                if (!formData.option1) {
                    valid = false;
                    newErrors.option1 = 'Bitte wählen Sie eine Option aus.';
                }
                break;
            case 2:
                if (!formData.option2) {
                    valid = false;
                    newErrors.option2 = 'Bitte wählen Sie einen Grund aus.';
                }
                break;
            case 3:
                if (!formData.number || parseInt(formData.number) <= 0) {
                    valid = false;
                    newErrors.number = 'Bitte geben Sie eine gültige Anzahl ein.';
                }
                break;
            case 4:
                if (!formData.option3) {
                    valid = false;
                    newErrors.option3 = 'Bitte wählen Sie Ihre Rolle aus.';
                }
                break;
            case 5:
                if (!formData.postalCode || !/^\d{5}$/.test(formData.postalCode)) {
                    valid = false;
                    newErrors.postalCode = 'Bitte geben Sie eine gültige Postleitzahl ein.';
                }
                break;
            case 6:
                if (!formData.firstName) {
                    valid = false;
                    newErrors.firstName = 'Bitte geben Sie Ihren Vornamen ein.';
                }
                if (!formData.lastName) {
                    valid = false;
                    newErrors.lastName = 'Bitte geben Sie Ihren Nachnamen ein.';
                }
                if (!formData.phoneNumber || !/^\d{10,15}$/.test(formData.phoneNumber)) {
                    valid = false;
                    newErrors.phoneNumber = 'Bitte geben Sie eine gültige Telefonnummer ein.';
                }
                if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
                    valid = false;
                    newErrors.email = 'Bitte geben Sie eine gültige E-Mail-Adresse ein.';
                }
                break;
            default:
                break;
        }

        setErrors(newErrors);
        return valid;
    };

    const nextStep = () => {
        if (validateStep()) {
            setStep(prevStep => prevStep + 1);
            setErrors({});
        }
    };

    const prevStep = () => {
        setStep(prevStep => prevStep - 1);
        setErrors({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateStep()) {
            // Prepare data for EmailJS
            const templateParams = {
                option1: formData.option1,
                option2: formData.option2,
                number: formData.number,
                option3: formData.option3,
                postalCode: formData.postalCode,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                email: formData.email
            };

            try {
                // Send email using EmailJS
                await emailjs.send(
                    'service_d41315s', // Replace with your EmailJS Service ID
                    'template_wg05az5', // Replace with your EmailJS Template ID
                    templateParams,
                    'vL8w8y040x5rEcv7u' // Replace with your EmailJS User ID
                );
                setMessage('Erfolgreich abgeschickt! Wir melden uns bei Ihnen!');
                navigate('/danke'); // Navigate to the /danke route on success
            } catch (error) {
                console.error('Error sending email:', error);
                setMessage('Da ist etwas schief gelaufen. Probieren Sie es später noch einmal.');
            }
        }
    };

    const progressPercentage = (step / totalSteps) * 100;

    return (
        <div className="form">
            <h2>Erhalten Sie Ihr Angebot</h2>

            {/* Progress Bar */}
            <div className="progress-bar-wrapper">
                <div className="progress-bar" style={{ width: `${progressPercentage}%` }}></div>
            </div>

            {/* Form Steps */}
            <div className="form-container">
                {/* Step 1 */}
                {step === 1 && (
                    <div>
                        <h3>Wie wird Ihr MFH aktuell verwaltet ?</h3>
                        <div className="hv-radio-container">
                            {['Selbstverwaltet', 'Anderer Verwalter', 'Neues Objekt', 'Anders'].map((option, index) => (
                                <label className="hv-radio" key={index}>
                                    <input
                                        type="radio"
                                        name="option1"
                                        value={option}
                                        checked={formData.option1 === option}
                                        onChange={handleChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                        {errors.option1 && <p className="error">{errors.option1}</p>}
                    </div>
                )}
 {/* Step 2 */}
 {step === 2 && (
                    <>
                        <h3>Warum kontaktieren Sie hamba ?</h3>
                        <div className="hv-select-wrapper">
                            <select
                                className="hv-select"
                                name="option2"
                                value={formData.option2}
                                onChange={handleChange}
                            >
                                <option value="">Bitte wählen</option>
                                <option value="Unzufrieden mit aktuellem Verwalter">Unzufrieden mit aktuellem Verwalter</option>
                                <option value="Keine Zeit für die Eigenverwaltung">Keine Zeit für die Eigenverwaltung</option>
                                <option value="Höhere Kompetenz und Transparenz">Höhere Kompetenz und Transparenz</option>
                                <option value="Aktuelle Hausverwaltung hört auf">Aktuelle Hausverwaltung hört auf</option>
                            </select>
                        </div>
                        {errors.option2 && <p className="error">{errors.option2}</p>}
                    </>
                )}

{/* Step 3 */}
{step === 3 && (
    <>
        <h3>Wie viele Wohneinheiten hat Ihr MFH ?</h3>
        <div className="hv-input-container">
            <input
                type="text"
                name="number"
                className="hv-input"
                value={formData.number}
                onChange={handleChange}
                placeholder="Anzahl Wohneinheiten"
            />
        </div>
        {errors.number && <p className="error">{errors.number}</p>}
    </>
)}

                {/* Step 4 */}
                {step === 4 && (
                    <div>
                        <h3>Was ist Ihre Rolle ?</h3>
                        <div className="hv-radio-container">
                            {['Alleineigentümer', 'Miteigentümer', 'Verwalter', 'Anders'].map((option, index) => (
                                <label className="hv-radio" key={index}>
                                    <input
                                        type="radio"
                                        name="option3"
                                        value={option}
                                        checked={formData.option3 === option}
                                        onChange={handleChange}
                                    />
                                    {option}
                                </label>
                            ))}
                        </div>
                        {errors.option3 && <p className="error">{errors.option3}</p>}
                    </div>
                )}

                {/* Step 5 */}
                {step === 5 && (
                    <>
                        <h3>Geben Sie die Postleitzahl Ihres MFH ein</h3>
                        <div className="hv-input-container">
                            <input
                                type="text"
                                name="postalCode"
                                className="hv-input"
                                value={formData.postalCode}
                                onChange={handleChange}
                                placeholder="z.B. 61462"
                                maxLength="5"
                            />
                        </div>
                        {errors.postalCode && <p className="error">{errors.postalCode}</p>}
                    </>
                )}

                {/* Step 6 */}
                {step === 6 && (
                    <div>
                        <h3>Gleich geschafft...</h3>
                        <div className="hv-input-grid">
                            <div className="hv-input-group">
                                <input
                                    type="text"
                                    name="firstName"
                                    className="hv-input-multi"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder="Vorname*"
                                />
                                {errors.firstName && <p className="error">{errors.firstName}</p>}
                            </div>
                            <div className="hv-input-group">
                                <input
                                    type="text"
                                    name="lastName"
                                    className="hv-input-multi"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Nachname*"
                                />
                                {errors.lastName && <p className="error">{errors.lastName}</p>}
                            </div>
                            <div className="hv-input-group">
                                <input
                                    type="tel"
                                    name="phoneNumber"
                                    className="hv-input-multi"
                                    value={formData.phoneNumber}
                                    onChange={handleChange}
                                    placeholder="Telefonnummer*"
                                />
                                {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                            </div>
                            <div className="hv-input-group">
                                <input
                                    type="email"
                                    name="email"
                                    className="hv-input-multi"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="E-Mail*"
                                />
                                {errors.email && <p className="error">{errors.email}</p>}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Navigation Buttons */}
            <div className={`hv-button-container ${step === 1 ? 'single-button-container' : 'hv-two-button-container'}`}>
                {step > 1 && <button className="hv-back-button" onClick={prevStep}>Zurück</button>}
                {step < totalSteps && (
                    <button
                        className="hv-next-button"
                        onClick={nextStep}
                    >
                        Weiter
                    </button>
                )}
                {step === totalSteps && (
                    <Link to="/danke">
                    <button
                        className="hv-next-button"
                        onClick={handleSubmit}
                    >
                        Senden
                    </button>
                    </Link>
                )}
            </div>

            {/* Success or Error Message */}
            {message && <p>{message}</p>}

            {/* Privacy Notice */}
            <small>
                Die Sicherheit Ihrer Daten ist uns sehr wichtig. Mit dem Klick auf "Senden" stimmen Sie unseren
                <a href="/agb" target='_blank'> Allgemeinen Nutzungsbedingungen </a> und unserer
                <a href="/datenschutz" target="_blank"> Datenschutzrichtlinie </a> zu.
            </small>
        </div>
    );
};

export default Form;