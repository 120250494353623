import React from 'react';

const ThreeStep = () => {
    return (
        <div className="three-step">
            <h1>Verwaltung die Sie lieben werden</h1>
            <h3 className='three-step-small'>Zufriedenheit <a>garantiert.</a> Oder Geld zurück.</h3>
            <div className="step">
                <div className="step-circle">1</div>
                <div className="step-content">
                    <h3 className="step-title">100% Service</h3>
                    <p className="step-text">Ab dem Moment unserer Verwaltungsübernahme kümmern wir uns um alles. Wirklich um alles. Und Sie lehnen sich zurück.</p>
                </div>
                <img src="phone.png" width="272" sizes="(max-width: 479px) 65vw, (max-width: 767px) 250px, (max-width: 991px) 300px, (max-width: 1279px) 23vw, 300px"></img>
            </div>
            <div className="step">
            <img src="discover.png" width="272" sizes="(max-width: 479px) 65vw, (max-width: 767px) 250px, (max-width: 991px) 300px, (max-width: 1279px) 23vw, 300px"></img>
            <div className="step-circle">2</div>
                <div className="step-content">
                    <h3 className="step-title">100% Sorgfalt</h3>
                    <p className="step-text">Wir verwalten Ihr Mehrfamilienhaus wie unsere eigenen. Das sagen wir nicht einfach so. Wir haben zuerst unsere eigenen Häuser verwaltet und kümmern uns um die Immobilien unserer Eigentümer mit der gleichen Sorgfalt.</p>
                </div>
            </div>
            <div className="step">
                <div className="step-circle">3</div>
                <div className="step-content">
                    <h3 className="step-title">100% Persönlich</h3>
                    <p className="step-text">Ab Tag 1 bekommt Ihr Mehrfamilienhaus einen zentralen Ansprechpartner, der für Sie und Ihre Mieter da ist. Wir kennen Ihr Haus mindestens so gut wie Sie.</p>
                </div>
                <img src="person_small.png" width="272" sizes="(max-width: 479px) 65vw, (max-width: 767px) 250px, (max-width: 991px) 300px, (max-width: 1279px) 23vw, 300px"></img>
            </div>
        </div>
        
    );
};

export default ThreeStep;
