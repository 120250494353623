import React, { useState } from 'react';
import { Link } from 'react-router-dom'; // Importiere Link von React Router


const Approach = () => {
    const [activeTab, setActiveTab] = useState('exchange'); // Default tab

    const renderContent = () => {
        switch (activeTab) {
            case 'exchange':
                return {
                    content: (
                        <div className="content-section">
                            <small>Austausch & Dokumentation</small>
                            <h4>Ein Ort für alle Anliegen Ihrer WEG</h4>
                            <p>Sie möchten die Teilungserklärung Ihrer WEG einsehen? Oder Neuigkeiten mit Ihren Miteigentümern teilen?</p>
                            <p>Das ist mit unserem Online-Portal problemlos möglich! So können Sie jederzeit auf alle WEG-Unterlagen zugreifen oder neue Dokumente sicher archivieren. Dadurch sparen Sie sich Zeit und Nerven bei der Suche.</p>
                            <p>Darüber hinaus bleiben Sie durch unser Online-Portal jederzeit mit Ihren Miteigentümern im Austausch. Neuigkeiten lassen sich ganz einfach teilen, wodurch jeder Eigentümer aus der WEG immer auf dem neuesten Stand bleibt.</p>
                            <Link to="/hausverwaltung"><button className="cta-button">Kostenloses Angebot</button></Link>
                        </div>
                    ),
                    image: 'computer.png',
                };
            case 'bank':
                return {
                    content: (
                        <div className="content-section">
                            <small>WEG-Bankkonto</small>
                            <h4>Ihr transparentes WEG-Bankkonto</h4>
                            <p>Mit unserem Online-Portal haben Sie jederzeit Einblick in die Finanzen Ihrer WEG. Verwalten Sie alle Zahlungen, Kontostände und Transaktionen übersichtlich an einem Ort.</p>
                            <p>Das transparente WEG-Bankkonto hilft Ihnen, die Finanzen Ihrer Eigentümergemeinschaft immer im Blick zu behalten.</p>
                            <Link to="/hausverwaltung"><button className="cta-button">Kostenloses Angebot</button></Link>
                        </div>
                    ),
                    image: 'person.png',
                };
            case 'meetings':
                return {
                    content: (
                        <div className="content-section">
                            <small>Eigentümerversammlungen</small>
                            <h4>Online Eigentümerversammlungen durchführen</h4>
                            <p>Organisieren und führen Sie Ihre Eigentümerversammlungen bequem online durch. Laden Sie Miteigentümer ein, stimmen Sie über wichtige Entscheidungen ab und dokumentieren Sie Ergebnisse direkt im Portal.</p>
                            <p>So sparen Sie Zeit und vereinfachen die Kommunikation in der Eigentümergemeinschaft.</p>
                            <Link to="/hausverwaltung"><button className="cta-button">Kostenloses Angebot</button></Link>
                        </div>
                    ),
                    image: 'speed.png',
                };
            default:
                return null;
        }
    };

    const { content, image } = renderContent();

    return (
        <div className="approach-container">
            <div className="approach-header">
                <h2>Unser Online-Portal</h2>
                <p>Dank unseres Portals behalten Sie jederzeit den Überblick über die Finanzen Ihrer WEG und können Eigentümerversammlungen bequem online durchführen und sich mit Ihren Miteigentümern austauschen.</p>
                <div className="tab-menu">
                    <span
                        className={`tab-item ${activeTab === 'exchange' ? 'active' : ''}`}
                        onClick={() => setActiveTab('exchange')}
                    >
                        Austausch & Dokumentation
                    </span>
                    <span
                        className={`tab-item ${activeTab === 'bank' ? 'active' : ''}`}
                        onClick={() => setActiveTab('bank')}
                    >
                        WEG-Bankkonto
                    </span>
                    <span
                        className={`tab-item ${activeTab === 'meetings' ? 'active' : ''}`}
                        onClick={() => setActiveTab('meetings')}
                    >
                        Eigentümerversammlungen
                    </span>
                </div>
            </div>
            <div className="approach-content">
                <div className="content-left">{content}</div>
                <div className="content-right">
                    <img src={image} alt="Approach illustration" />
                </div>
            </div>
        </div>
    );
};

export default Approach;