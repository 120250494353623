import React, { useState } from 'react';
import { FaLock } from 'react-icons/fa'; // Font Awesome Lock Icon
import { useLocation } from 'react-router-dom'; // Import useLocation for route checking

const Cost = () => {
  const [showAll, setShowAll] = useState(false);
  const location = useLocation();

  // Check if the current route matches /hausverwaltung/:slug
  const isHausverwaltungRoute = location.pathname.startsWith('/hausverwaltung/') || location.pathname === '/kontakt';
  
  const data = [
    { service: 'Verwaltungsgebühr</br>(pro Einheit)', hamba: '', hausverwalter: '30€+' },
    { service: 'Vermietungsgebühr</br>(1. Kaltmiete)', hamba: '100%', hausverwalter: '200% - 300%' },
    { service: 'Verwaltungsgebühr</br>bei Leerstand', hamba: '0 €', hausverwalter: '> 30 € / Einheit' },
    { service: 'Vertragslaufzeit', hamba: 'Monatlich kündbar', hausverwalter: '> 1 Jahr' },
    { service: 'Zahlungsintervall', hamba: 'Nachschüssig', hausverwalter: 'Vorschüssig' },
    { service: 'Monatliche</br>Berichte', hamba: 'Inklusive', hausverwalter: 'Nicht verfügbar' },
    { service: 'Cash Flow &</br>Finanzanalyse', hamba: 'Inklusive', hausverwalter: 'Nicht verfügbar' },
    { service: 'Regelmäßige</br>Inspektionen', hamba: 'Inklusive', hausverwalter: 'Zusätzliche Gebühr' },
    { service: 'Abwicklung</br>von Schäden', hamba: 'Inklusive', hausverwalter: 'Zusätzliche Gebühr' },
    { service: 'Portokosten', hamba: 'Inklusive', hausverwalter: 'Zusätzliche Gebühr' },
  ];

  const toggleRows = () => {
    setShowAll(!showAll);
  };

  const handleLockClick = (e) => {
    e.preventDefault();
    if (isHausverwaltungRoute) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      window.open("/standort", "_blank", "noopener,noreferrer");
    }
  };

  return (
    <div className='table-container'>
      <h1 className='cost-heading'>Preise die zu Ihnen passen</h1>
      <h3 className='cost-subheading'>Keine versteckten Kosten. Nur <a>großartiger</a> Service.</h3>
      <table className="styled-table">
        <thead>
          <tr>
            <th></th>
            <th className='middle-heading'><img className='table-logo' src='/hamba.png' alt='hamba logo' /></th>
            <th>Hausverwalter</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? 'even-row' : 'odd-row'}
              style={{
                display: showAll || index < 5 ? 'table-row' : 'none',
                minHeight: '600px',
                transition: 'all 0.5s ease-in-out',
                filter: !showAll && index === 4 ? 'blur(1.5px)' : 'none'
              }}
            >
              <td className='left-column' dangerouslySetInnerHTML={{__html: row.service}}></td>
              <td className='middle-column'>
                {index === 0 ? (
                  <a
                    href="/standort"
                    onClick={handleLockClick}
                  >
                    <FaLock size={20} color="#1b7ff6"/><br></br>Freischalten
                  </a>
                ) : (
                  row.hamba
                )}
              </td>
              <td className='right-column'>{row.hausverwalter}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="toggle-container">
        <button className="toggle-button" onClick={toggleRows}>
          {showAll ? 'Weniger anzeigen' : 'Mehr anzeigen'}
        </button>
      </div>
    </div>
  );
};

export default Cost;