import React from 'react';
import { Link } from 'react-router-dom'; // Importiere Link von React Router

const Owner = () => {
  return (
    <section className="owner-section">
      <div className="owner-container">
        <div className="owner-image">
          <img
            src="multifamily.png" // Replace with your image source
            alt="Description of image"
          />
        </div>
        <div className="owner-content">
          <h2>Besitzen Sie ein Mehrfamilienhaus?</h2>
          <p>Wir sorgen für Ihr Vermögen und Sie können ruhig schlafen.
          </p>
          <div className="owner-button-space">
            <Link to="/standort">
            <button>Kostenloses Angebot</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Owner;