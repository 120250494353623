import React from 'react';

const Change = () => {
  return (
    <section className="change-section">
      <div className="change-header">
        <h1 className="change-heading">In 1, 2, 3 zu hamba wechseln</h1>
        <h3 className="change-small-text">Wechseln Sie zu uns. Es <a>lohnt</a> sich.</h3>
      </div>

      <div className="change-steps">
        {/* Schritt 1 */}
        <div className="change-step">
        <img width="33.5" loading="lazy" alt="" src="Step1.png"></img>          
        <div className="change-step-content">
            <p className="step-paragraph">Die ersten Details erhalten wir von Ihnen in unserem <a href="standort" target='_blank'>Formular</a>. Wir melden uns zeitnah telefonisch bei Ihnen um in Ruhe Ihre aktuelle Situation zu verstehen und die näheren Details einer Verwaltungsübernahme durch uns zu besprechen. Anschließend erstellen wir Ihnen ein individuelles Angebot.</p>
          </div>
        </div>

        {/* Schritt 2 */}
        <div className="change-step">
        <img width="33.5" loading="lazy" alt="" src="Step2.png"></img>          
        <div className="change-step-content">
            <p className="step-paragraph">Wir konnten Sie überzeugen? Sofort nach Vertragsunterzeichnung bereiten wir den reibungslosen Start der Verwaltung vor und legen für Sie los.</p>
          </div>
        </div>

        {/* Schritt 3 */}
        <div className="change-step">
        <img width="33.5" loading="lazy" alt="" src="Step3.png"></img>          
        <div className="change-step-content">
            <p className="step-paragraph">Herzlich Willkommen bei hamba! Wir kümmern uns ab jetzt um alles. Dafür informieren wir umgehend alle relevanten Parteien und übernehmen alle Aspekte der Verwaltung für Sie. Aber keine Sorge Sie sind immer über die wichtigsten Entwicklungen im Bilde.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Change;
