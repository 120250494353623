// src/components/ThankYou.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
    const navigate = useNavigate();

    return (
        <div className="thank-you-container">
            <div className="icon-container">
            <img
          src="thankyou.png"
          loading="lazy"
          width="380"
          alt="hero image"
          className="pic-naufrage hide-on-tablet"
        />
            </div>
            <h1>Vielen Dank!</h1>
            <p>Wir freuen uns über Ihre Kontaktaufnahme und melden uns bei Ihnen, sobald wir Ihre Anfrage bearbeitet haben!</p>
        </div>
    );
};

export default ThankYou;