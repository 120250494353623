import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LeadForm from './LeadForm';
import Form from './Form';

const Blog = ({ articles }) => {
  const { slug } = useParams();
  const article = articles.find((article) => article.slug === slug);

  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!article) {
    return <div>Artikel nicht gefunden</div>;
  }

  const getCategoryClass = (category) => {
    switch (category.toLowerCase()) { // Kategorien auf Kleinbuchstaben vergleichen
      case 'news':
        return 'category-news';
      case 'vermietung':
        return 'category-vermietung';
      case 'verwaltung':
        return 'category-verwaltung';
      default:
        return ''; // Fallback
    }
  };

  return (
    <div className="blog-detail">
      <img src={article.mainImage} alt={article.title} className="blog-main-image" />
      <div className="blog-content">
        <h1>{article.title}</h1>
        <div className="blog-meta">
          <span className={`blog-category ${getCategoryClass(article.category)}`}>
            {article.category}
          </span>
          <span className="blog-date">{article.date}</span>
        </div>
        <div className="blog-author">
          <img src={article.authorImage} alt={article.author} className="author-image" />
          <span>{article.author}</span>
        </div>
        <p>{article.description}</p>
        {/* Conditionally render LeadForm or Form based on screen size */}
        {isMobile ? <Form /> : <LeadForm />}
        <div className="blog-body">
          {article.content}
        </div>
      </div>
    </div>
  );
};

export default Blog;