// src/components/Button.js
import React from 'react';
import { Link } from 'react-router-dom'; // Importiere Link von React Router

const ButtonBlue = ({ text, onClick }) => {
    return (
        <div className="button-blue-container">
            <Link to="/standort">
            <button className="custom-blue-button" onClick={onClick}>
                Kostenloses Angebot anfordern
            </button>
            </Link>
        </div>
    );
};

export default ButtonBlue;
