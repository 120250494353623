import React from 'react';
import { useNavigate } from 'react-router-dom';

// Array of city names
const cities = [
  'Frankfurt', 'Wiesbaden', 'Hochtaunuskreis', 'Main-Taunus-Kreis', 'Bad Soden', 'Oberursel', 
  'Bad Homburg', 'Friedrichsdorf', 'Neu-Isenburg', 'Offenbach', 'Dreieich', 'Rüsselsheim',
  'Langen', 'Hanau', 'Seligenstadt', 'Darmstadt', 'Limburg' 
];

const Location = () => {
  const navigate = useNavigate();

  const handleCityClick = (city) => {
    navigate(`/hausverwaltung/${city}`);
  };

  const getImageSrc = (city) => {
    // Use relative path to access images from the public folder
    return `/${city.toLowerCase()}.png`;
  };

  return (
    <>
      <div className='location-wrapper'>
        <h1>Wo steht Ihre Immobilie ?</h1>
        <div className="location-container">
        <div
            className="location-box"
            onClick={() => handleCityClick('/')}
          >
            <img
              src="/hessen.png"
              alt="Andere"
              className="location-image"
            />
            <p>Andere</p>
          </div>
          {cities.map((city) => (
            <div
              key={city}
              className="location-box"
              onClick={() => handleCityClick(city)}
            >
              <img
                src={getImageSrc(city)}
                alt={city}
                className="location-image"
              />
              <p>{city}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Location;