// src/components/Hero.js
import React from 'react';

const ManagementHero = () => {
  return (
    <section className="management-hero">
      <div className="management-hero-content">
        <h1>Von Eigentümern für Eigentümer</h1>
        <p>Mit höchster Sorgfalt und klarem Fokus auf Ergebnisse übernehmen wir die Verwaltung Ihrer Immobilie. Wir kombinieren moderne Technologien mit persönlichen, engagierten Service. Ihr Vertrauen ist unser Maßstab - wir arbeiten täglich daran, den Wert Ihrer Immobilie nachhaltig zu sichern und kontinuierlich zu steigern.</p>
      </div>
      <div className="hero-gif">
      <img src="hero-animation.gif" loading="lazy" width="416" alt=""></img>
      </div>
    </section>
  );
};

export default ManagementHero;

