import React from 'react';
import { useParams } from 'react-router-dom';


const Case = ({ caseStudies }) => {
  const { slug } = useParams();
  const caseStudy = caseStudies.find(study => study.slug === slug);

  if (!caseStudy) {
    return <div>Case Study not found</div>;
  }

  return (
    <div className="container-default">
        <div className="split-content case-study-left">
          <div className="case-study-company-wrapper">
            <div className="case-study-company-page">{caseStudy.company}</div>
            <div className="dash case-study-company-dash"></div>
            <div className="case-study-date">{caseStudy.date}</div>
          </div>
          <h1>{caseStudy.title}</h1>
          <p className="paragraph case-study-page">{caseStudy.description}</p>
        </div>
      <div className="divider case-study"></div>
      <div className="about-case-study-grid">
        <div className="about-case-study-wrapper">
          <div className="heading-h4-size">Service</div>
          <p>{caseStudy.services}</p>
        </div>
        <div className="about-case-study-wrapper">
          <div className="heading-h4-size">Tätigkeit</div>
          <p>{caseStudy.platforms}</p>
        </div>
        <div className="about-case-study-wrapper result-1">
          <div className="case-study-result-number">{caseStudy.result1.quantity}</div>
          <div>{caseStudy.result1.text}</div>
        </div>
        <div className="about-case-study-wrapper result-2">
          <div className="case-study-result-number">{caseStudy.result2.quantity}</div>
          <div>{caseStudy.result2.text}</div>
        </div>
      </div>
      <div className="image-wrapper case-study-page">
        <img alt={caseStudy.title} src={caseStudy.image} className="image case-study-main" />
      </div>
      <div id="read-more" className="project-overview-wrapper">
        <div className="split-content project-overview-left">
          <h2>Project Overview</h2>
          <div className="accent"></div>
        </div>
        <div className="split-content project-overview-right">
          <div className="rich-text">
            <p>{caseStudy.overview}</p>
            <ul>
              {caseStudy.overviewPoints.map((point, index) => (
                <li key={index}>{point}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Case;
