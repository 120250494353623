import React from 'react';
import VerticalCarousel from './VerticalCarousel'; // Import the VerticalCarousel component

const Hero = () => {
  const phrases = [
    "Experten an Ihrer Seite.",
    "geprüften Mietern.",
    "korrekten Abrechnungen.",
    "transparenten Kosten.",
    "pünktlichen Mieten.",
    "digitalen Lösungen.",
    "hamba."
  ];

  return (
    <section className="hero">
      <div className="hero-content">
        <h1>Immobilienverwaltung kann so einfach sein</h1>
        <p>
          <span>Mit </span>
          <VerticalCarousel
            items={phrases}
            displayDuration={2500}     // Display each item for 2.5 seconds
            transitionDuration={500}   // Transition duration of 0.5 seconds
          />
        </p>
      </div>
      <div className="hero-img">
        <img
          src="/hero.png"
          loading="lazy"
          width="380"
          alt="hero image"
        />
      </div>
    </section>
  );
};

export default Hero;