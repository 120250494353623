// src/components/Button.js
import React from 'react';
import { Link } from 'react-router-dom'; // Importiere Link von React Router

const ButtonWhite = ({ text, onClick }) => {
    return (
        <div className="button-white-container">
             <Link to="/standort">
            <button className="custom-white-button" onClick={onClick}>
                Kostenloses Angebot anfordern
            </button>
            </Link>
        </div>
    );
};

export default ButtonWhite;
