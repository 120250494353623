import React from 'react';
import { useParams } from 'react-router-dom';

const FormHeader = () => {
  const { city } = useParams(); // Holen des Städtenamens aus der URL

  const headline = city ? `Hausverwaltung ${city}` : "Professionelle Hausverwaltung"; // Neutrale Überschrift, falls kein city-Param

  const description = city 
    ? `Entdecken Sie unser Angebot für die professionelle Verwaltung Ihrer Immobilie.` 
    : "Entdecken Sie unser Angebot für die professionelle Verwaltung Ihrer Immobilie.";

  return (
    <section className="form-header">
      <div className="header-content">
        {city && <small>Verwaltung {city}</small>}
        <h1>{headline}</h1> {/* Neutrale oder dynamische Überschrift */}
        <p>{description}</p>
      </div>
    </section>
  );
};

export default FormHeader;
