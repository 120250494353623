// src/components/Datenschutz.js
import React from 'react';

const Datenschutz = () => {
    return (
        <>
            <div className='impressum-container'>
                <p>hamba bietet zwei Kontaktformulare an, für die die folgenden datenschutzrechtlichen Regelungen gelten sollen:
                    <br></br>
                    <br></br>
                    Damit Sie Ihre Interesse an einer Angebotsstellung durch hamba bekundigen können, erhebt und speichert hamba persönliche Daten, die der Nutzer in einem von zwei Kontaktformularen selbst zur Verfügung stellen kann. Die Vertraulichkeit und Sicherheit dieser persönlichen Daten ist und bleibt unsere höchste Priorität. Dies ist unser Ziel und diese Datenschutzrichtlinie ("Richtlinie") erläutert im Folgenden, was wir damit meinen. 
                    <br></br>
                    <br></br>
                    
                    Diese Richtlinie enthält wesentliche Informationen über die Verwendung Ihrer persönlichen Daten durch die hamba Gmbh und gilt für alle hamba-Dienste und die damit verbundenen Dienstleistungen. Von Zeit zu Zeit entwickeln und bieten wir neue Dienste oder zusätzliche Angebote an. Wenn die Einführung dieser neuen Dienste oder zusätzlichen Angebote zu einer Änderung der Art und Weise führt, wie wir Ihre personenbezogenen Daten erfassen und verarbeiten, werden wir Ihnen weitere Informationen und zusätzliche Bedingungen oder Richtlinien zur Verfügung stellen. Sofern es nicht anders angegeben wird, unterliegen alle neuen Dienste oder zusätzlichen Angebote, die hinzugefügt werden, dieser Richtlinie.
                    <br></br>
                    <br></br>

                    <h2>Wie sammeln wir Ihre persönlichen Daten?</h2>
                    Wir sammeln Ihre persönlichen Daten auf folgende Weise:

                    Wenn Sie auf der hamba Website über das mehrseitige Kontaktformular oder über das verkürzte Kontaktformular an uns wenden: 
                    <br></br>
                    <br></br>
                    Wir erfassen bestimmte personenbezogene Daten wie Ihre E-Mail-Adresse, Telefonnummer und Ihren Namen, um Sie zu kontaktieren und/oder Ihnen ein Angebot für unsere Dienstleistung anzubieten.
                    <br></br>
                    <br></br>
                    Über Dienste von Drittanbietern, die in die hamba-Anwendung integriert sind: 
                    <br></br>
                    <br></br>
                    hamba bietet die Integration mit Diensten von Drittanbietern direkt von seiner Plattform aus an. Einige dieser Dienste zeichnen persönliche Daten auf und teilen diese mit hamba.
                    Wir verwenden aggregierte und anonymisierte Informationen, um den hamba-Service zu verbessern, unsere Computersysteme zu testen, Forschung zu betreiben, Daten zu analysieren und neue Funktionen zu entwickeln.
                    <br></br>
                    <br></br>

                    <h2>Art der personenbezogenen Daten</h2>
                    Persönliche Daten, die bei der Kontaktaufnahme auf der Website erfasst werden
                    <br></br>
                    <br></br>
                    Dies sind die persönlichen Daten, die Sie angeben, um auf der hamba-Website Kontakt aufnehmen zu können. Hiervon umfasst sind Namen, Vornamen, E-Mail-Adressen, Telefonnummern und allgemeine Informationen über Ihr Mehrfamilienhaus.
                    <br></br>
                    <br></br>
                    hamba verpflichtet sich, die Daten seiner Benutzer zu schützen und die Weitergabe von Daten so weit wie möglich einzuschränken. Jegliche Weitergabe von Daten ist entweder notwendig, um den hamba-Service bereitzustellen oder gerechtfertigt, um den hamba-Service zu verbessern. hamba verkauft die persönlichen Daten seiner Benutzer nicht.
                    <br></br>
                    <br></br>

                    <h2>Ihre Rechte</h2>
                    Die so genannte "Datenschutz-Grundverordnung" oder "DSGVO", gewährt Einzelpersonen bestimmte Rechte in Bezug auf ihre personenbezogenen Daten, darunter:
                    <br></br>
                    <br></br>
                    Auskunftsrecht: Diese Richtlinie informiert unsere Nutzer über die Art und Verwendung der personenbezogenen Daten;
                    Recht auf Zugang, Berichtigung und Löschung: 
                    <br></br>
                    Wenden Sie sich an uns, um alle Ihre personenbezogenen Daten zu erhalten/berichtigen/löschen zu lassen;
                    <br></br>
                    <br></br>
                    Recht auf Einschränkung: Wenden Sie sich an uns, um zu verlangen, dass die Verarbeitung aller oder eines Teils Ihrer personenbezogenen Daten vorübergehend oder dauerhaft eingestellt wird;
                    <br></br>
                    <br></br>
                    Verweigerungsrecht: Wenden Sie sich an uns, um die Verarbeitung Ihrer personenbezogenen Daten für Direktmarketing Zwecke abzulehnen;
                    <br></br>
                    <br></br>
                    Recht auf auf Datenübertragbarkeit: Wenden Sie sich an uns, um eine Kopie Ihrer personenbezogenen Daten in einem strukturierten elektronischen Format zu erhalten, das leicht übertragbar ist;
                    <br></br>
                    <br></br>
                    Recht, keiner automatisierten Entscheidungsfindung unterworfen zu werden: Wir verwenden keine automatisierte Entscheidungsfindung.
                    Sie haben das Recht, erteilte Einwilligungen gemäß Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen.
                    Die Einwilligung kann jederzeit formlos, z.B. per E-Mail an info@hambacore.com oder per Brief an die hamba GmbH, Limburger Str. 17, 61462 Königstein im Taunus, mit Wirkung für die Zukunft widerrufen werden.
                    <br></br>
                    <br></br>

                    <h2>Datenaufbewahrung</h2>
                    hamba speichert Ihre persönlichen Daten nur so lange, wie es notwendig ist, um Ihnen den hamba-Service zur Verfügung zu stellen und für legitime und wesentliche Geschäftszwecke wie die Einhaltung unserer gesetzlichen Verpflichtungen und die Beilegung von Streitigkeiten. Auf Ihren Wunsch hin werden wir Ihre personenbezogenen Daten löschen oder anonymisieren, so dass Sie nicht mehr identifiziert werden können, es sei denn, es ist uns gesetzlich gestattet oder vorgeschrieben, bestimmte personenbezogene Daten aufzubewahren. Im Falle eines Vertrages zwischen der Eigentümerschaft und hamba unterliegt die Speicherung bestimmter personenbezogener Daten der Eigentümerschaft den Bestimmungen des Vertrages. hamba speichert alle Daten innerhalb der Europäischen Union und überträgt keine personenbezogenen Daten außerhalb der Europäischen Union. Die Server von hamba werden von Microsoft Azure verwaltet, die mit der DSGVO auf dem neuesten Stand sind.
                    <br></br>
                    <br></br>

                    <h2>Sicherheit der persönlichen Daten</h2>
                    hamba verpflichtet sich, die persönlichen Daten seiner Benutzer zu schützen. Wir setzen angemessene technische und organisatorische Maßnahmen ein, um die Sicherheit Ihrer personenbezogenen Daten zu schützen. Bitte beachten Sie jedoch, dass kein System vollständig sicher ist.
                    <br></br>
                    <br></br>

                    <h2>Kinder</h2>
                    hamba bietet seine Dienste nicht für Kinder an. Aufgrund der Art der angebotenen Dienstleistung (MFH Mietverwaltung) haben wir es nicht für notwendig erachtet, das Alter unserer Nutzer zu kontrollieren.
                    <br></br>
                    <br></br>

                    <h2>Änderungen an dieser Datenschutzrichtlinie</h2>
                    Wir können von Zeit zu Zeit Änderungen an dieser Richtlinie vornehmen. Wenn wir Änderungen an dieser Richtlinie vornehmen, werden wir Sie durch entsprechende Hinweise benachrichtigen, z. B. indem wir Ihnen eine E-Mail schicken. Wir können Sie über solche Änderungen im Voraus informieren.
                    <br></br>
                    <br></br>

                    <h2>Nehmen Sie mit uns Kontakt auf</h2>
                    Vielen Dank, dass Sie unsere Datenschutzrichtlinie gelesen haben. Wenn Sie Fragen zu dieser Richtlinie haben, kontaktieren Sie uns bitte unter info@hambacore.com.
                </p>
            </div>
        </>
    );
};

export default Datenschutz;