import React from 'react';
import { Link } from 'react-router-dom';

const CaseOverview = ({ caseStudies }) => {
  return (
    <section className="section case-studies">
      <div className="container-default">
        <div className="case-studies-grid">
          {caseStudies.map((study) => (
            <div key={study.id} className="case-study">
              <div className="card case-study">
                <Link to={`/case/${study.slug}`} className="image-wrapper case-study">
                  <img
                    alt={study.title}
                    src={study.image}
                    className="image case-study"
                  />
                </Link>
                <div className="case-study-card-content">
                  <div className="case-study-company">{study.company}</div>
                  <Link to={`/case/${study.slug}`} className="case-study-title-link">
                    <h2 className="title case-study-card-title">{study.title}</h2>
                  </Link>
                  <p>{study.description}</p>
                  <div className="divider case-study-card"></div>
                  <div className="case-study-card-results-grid">
                    <div className="case-study-card-result-wrapper">
                      <div className="increased-quantity card-case-study">{study.result1.quantity}</div>
                      <div className="increased-text">{study.result1.text}</div>
                    </div>
                    <div className="case-study-card-result-wrapper">
                      <div className="increased-quantity card-case-study">{study.result2.quantity}</div>
                      <div className="increased-text">{study.result2.text}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CaseOverview;
