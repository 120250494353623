export const caseStudies = [
    {
      id: 1,
      slug: 'wiesbaden-18-einheiten',
      company: 'Wiesbaden',
      title: 'Wie wir ein 18 Einheiten MFH in Wiesbaden in 30 Tagen vollvermietet haben',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      date: 'Oktober 2024',
      image: '/path-to-image.jpg',
      services: 'Mietverwaltung',
      platforms: 'Verwaltungsübernahme',
      result1: { quantity: '+50%', text: 'Monatliche Mieteinnahmen' },
      result2: { quantity: '0%', text: 'Ausfallquote nach 2 Monaten' },
      overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      overviewPoints: [
        'Lorem ipsum dolor sit amet',
        'Nunc sed velit dignissim sodales',
        'Ut sem viverra aliquet',
      ],
    },
      // Weitere Case Studies hier
  ];
  