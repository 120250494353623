import React, { useState, useEffect, useRef } from 'react';

const VerticalCarousel = ({ items, displayDuration, transitionDuration }) => {
  const listRef = useRef(null); // Reference to the list element
  const [currentIndex, setCurrentIndex] = useState(0); // Track the current index
  const itemHeight = 50; // Height of each item (ensure it matches the CSS)
  const totalItems = items.length;

  useEffect(() => {
    const listElement = listRef.current;
    let isAnimating = false;

    const interval = setInterval(() => {
      if (isAnimating) return;
      isAnimating = true;

      setCurrentIndex(prevIndex => {
        let newIndex = prevIndex + 1;

        // Start the transition
        listElement.style.transition = `transform ${transitionDuration}ms ease-in-out`;
        listElement.style.transform = `translateY(-${newIndex * itemHeight}px)`;

        // After the transition ends
        setTimeout(() => {
          isAnimating = false;

          // If we've reached the end, reset without visible jump
          if (newIndex >= totalItems) {
            // Disable transition for the reset
            listElement.style.transition = 'none';
            listElement.style.transform = `translateY(0px)`;

            // Reset index
            newIndex = 0;
            setCurrentIndex(newIndex);
          }
        }, transitionDuration);

        return newIndex;
      });
    }, displayDuration + transitionDuration);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [items, displayDuration, transitionDuration]);

  // Clone items and add the first item at the end to enable seamless looping
  const clonedItems = [...items, items[0]];

  return (
    <div className="v-carousel-mask">
      <ul ref={listRef} className="carousel-list">
        {clonedItems.map((item, index) => (
          <li key={index} className="carousel-item">
            <span className="underline">{item}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default VerticalCarousel;