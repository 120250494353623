import React from 'react';
import { Link } from 'react-router-dom';

// Helper function to convert German date format to ISO format
const monthMapping = {
  Januar: '01',
  Februar: '02',
  März: '03',
  April: '04',
  Mai: '05',
  Juni: '06',
  Juli: '07',
  August: '08',
  September: '09',
  Oktober: '10',
  November: '11',
  Dezember: '12',
};

const convertGermanDate = (dateStr) => {
  const [day, monthName, year] = dateStr.split(' ');
  const month = monthMapping[monthName];
  return `${year}-${month}-${day.replace('.', '')}`;
};

const Footer = ({ articles }) => {
  // Sort the articles by date (latest first) and select the top 4
  const latestArticles = articles
    .sort((a, b) => new Date(convertGermanDate(b.date)) - new Date(convertGermanDate(a.date)))
    .slice(0, 4); // Get the four most recent articles

  return (
    <>
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo">
          <Link to="/"><img src="/hamba.png" alt="Logo" /></Link>
        </div>
        
        <div className="footer-links">
          <div className="footer-column">
            <h4>Unternehmen</h4>
            <ul>
            <li><a href="/verwaltung">Verwaltung</a></li>
              <li><a href="/kontakt">Kontakt</a></li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Wissen</h4>
            <ul>
              <li><a href="/blog">Blog</a></li>
              {/*<li><a href="/case">Case Studies</a></li>*/}
            </ul>
          </div>
          <div className="footer-column">
            <h4>Neueste Blog-Artikel</h4>
            <ul>
              {latestArticles.map((article) => (
                <li key={article.id}>
                  <Link to={`/blog/${article.slug}`}>{article.title}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="footer-container">
        <div className="footer-support">
          <h4>Wo können wir Sie unterstützen?</h4>
          <div className="footer-cities">
            <div className="footer-column">
              <ul>
                <li><Link to="/hausverwaltung/Frankfurt">Frankfurt</Link></li>
                <li><Link to="/hausverwaltung/Wiesbaden">Wiesbaden</Link></li>
                <li><Link to="/hausverwaltung/Hochtaunuskreis">Hochtaunuskreis</Link></li>
                <li><Link to="/hausverwaltung/Main-Taunus-Kreis">Main-Taunus-Kreis</Link></li>
                <li><Link to="/hausverwaltung/Hanau">Hanau</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <ul>
                <li><Link to="/hausverwaltung/Kassel">Kassel</Link></li>
                <li><Link to="/hausverwaltung/Darmstadt">Darmstadt</Link></li>
                <li><Link to="/hausverwaltung/Marburg">Marburg</Link></li>
                <li><Link to="/hausverwaltung/Fulda">Fulda</Link></li>
                <li><Link to="/hausverwaltung/Bad Homburg">Bad Homburg</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <ul>
                <li><Link to="/hausverwaltung/Rüsselsheim">Rüsselsheim</Link></li>
                <li><Link to="/hausverwaltung/Limburg">Limburg</Link></li>
                <li><Link to="/hausverwaltung/Langen">Langen</Link></li>
                <li><Link to="/hausverwaltung/Seligenstadt">Seligenstadt</Link></li>
                <li><Link to="/hausverwaltung/Offenbach">Offenbach</Link></li>
              </ul>
            </div>
            <div className="footer-column">
              <ul>
                <li><Link to="/hausverwaltung/Dreieich">Dreieich</Link></li>
                <li><Link to="/hausverwaltung/Bad Soden">Bad Soden</Link></li>
                <li><Link to="/hausverwaltung/Friedrichsdorf">Friedrichsdorf</Link></li>
                <li><Link to="/hausverwaltung/Neu-Isenburg">Neu-Isenburg</Link></li>
                <li><Link to="/hausverwaltung/Oberursel">Oberursel</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="footer-divider" />
      <div className="footer-info">
        <p>hamba GmbH, Limburger Str. 17, 61462 Königstein im Taunus. <a href="/agb">AGB</a> | <a href="/impressum">Impressum</a> | <a href="/datenschutz">Datenschutz</a></p>
      </div>
    </footer>
    
        <footer className="mobile-footer">
        {/* Logo */}
        <div className="mobile-footer-logo">
          <Link to="/">
            <img src="/hamba.png" alt="Logo" />
          </Link>
        </div>
  
        {/* Footer Links */}
        <div className="mobile-footer-links">
          <div className="mobile-footer-column">
            <h4>Unternehmen</h4>
            <ul>
              <li><Link to="/verwaltung">Verwaltung</Link></li>
              <li><Link to="/kontakt">Kontakt</Link></li>
            </ul>
          </div>
  
          <div className="mobile-footer-column">
            <h4>Wissen</h4>
            <ul>
              <li><Link to="/blog">Blog</Link></li>
            </ul>
          </div>
  
          <div className="mobile-footer-column">
            <h4>Rechtliches</h4>
            <ul>
              <li><Link to="/agb">AGB</Link></li>
              <li><Link to="/impressum">Impressum</Link></li>
              <li><Link to="/datenschutz">Datenschutz</Link></li>
            </ul>
          </div>
        </div>
        {/* Footer Info */}
        <div className="mobile-footer-info">
          <p>
            hamba GmbH, Limburger Str. 17, 61462 Königstein im Taunus. <br/>
          </p>
        </div>
      </footer>
      </>
  );
};

export default Footer;
