import React from 'react';
import ButtonContact from './ButtonContact';



const Why = () => {
    // Funktion zum Scrollen zum Anfang der Seite
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: für einen glatten Übergang
        });
    };
    return (
        <section className="why-section">
            <div className="why-content">
                {/* Rechte Seite: Überschrift und Text */}
                <div className="why-steps">
                    {/* Schritt 1 */}
                    <div className="why-step">
                        <div className="step-icon">
                            <img width="33.5" loading="lazy" alt="" src="Step1.png"></img>
                        </div>
                        <div className="step-content">
                            <h3 className="step-title">Zuerst lernen wir einander kennen</h3>
                            <p className="step-description">
                                Die ersten Details erhalten wir von Ihnen in unserem <a
                                    onClick={handleScrollToTop}
                                    style={{
                                        color: '#1b7ff6',
                                        textDecoration: 'none',
                                        cursor: 'pointer'
                                    }}
                                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                                onMouseLeave={(e) => e.target.style.color = '#1b7ff6'}>Formular</a>. Wir melden uns zeitnah telefonisch bei Ihnen um in Ruhe Ihre aktuelle Situation zu verstehen und die näheren Details einer Verwaltungsübernahme durch uns zu besprechen. Anschließend erstellen wir Ihnen ein individuelles Angebot.
                            </p>
                        </div>
                    </div>

                    {/* Schritt 2 */}
                    <div className="why-step">
                        <div className="step-icon">
                            <img width="33.5" loading="lazy" alt="" src="Step2.png"></img>
                        </div>
                        <div className="step-content">
                            <h3 className="step-title">Dann werden wir uns vertraglich einig</h3>
                            <p className="step-description">
                            Wir konnten Sie überzeugen? Sofort nach Vertragsunterzeichnung bereiten wir den reibungslosen Start der Verwaltung vor und legen für Sie los.
                            </p>
                        </div>
                    </div>

                    {/* Schritt 3 */}
                    <div className="why-step">
                        <div className="step-icon">
                            <img width="33.5" loading="lazy" alt="" src="Step3.png"></img>
                        </div>
                        <div className="step-content">
                            <h3 className="step-title">Willkommen bei hamba!</h3>
                            <p className="step-description">
                            Herzlich Willkommen bei hamba! Ab jetzt kümmern wir uns um alles.
                            </p>
                            <p className="step-description">
                            Wir informieren alle relevanten Parteien und übernehmen alle Aspekte der Verwaltung für Sie. Aber keine Sorge Sie sind immer über die wichtigsten Entwicklungen im Bilde.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="why-intro">
                    <h1 className="why-title">Warum Sie sich für uns entscheiden sollten?</h1>
                    <h3>Zu <a>hamba</a> wechseln.</h3>
                    <p className="why-description">
                     Weil wir uns sicher sind, dass Sie zufrieden sein werden. Wir sind uns sogar so sicher, dass Sie in den ersten Wochen ein unbedingtes Rücktrittsrecht haben. Überzeugen Sie sich selbst und schauen Sie sich hamba von innen an.    
                    </p>
                    <ButtonContact />
                </div>
                {/* Linke Seite: Schritte */}
            </div>
        </section>
    );
};

export default Why;
