import React from 'react';

const About = () => {
  return (
    <section className="about-section">
      <div className="about-heading">
        <h1>Von Eigentümern für Eigentümer</h1>
        <h2>Wir <a>wissen</a> wovon wir reden.</h2>
        <p className="about-description">Weil wir zuerst selbst Eigentümer waren. Bevor wir das erste Mehrfamilienhaus fremdverwaltet haben waren wir selbst langjährig erfolgreiche Eigentümer und Eigenverwalter. Ein kurzer Auszug aus unserem persönlichen Portfolio im Rhein-Main-Gebiet:</p>
      </div>

<div className='bubble-wrapper'>
      <div className="statistics">
        <div className="stat-item">
          <h2>98<span>%</span></h2>
          <p>Zahlungsquote</p>
        </div>
        <div className="stat-item">
          <h2>15<span>+</span></h2>
          <p>Jahre Erfahrung</p>
        </div>
        <div className="stat-item">
          <h2><span>&lt;</span>1</h2>
          <p>Monat Neuvermietung</p>
        </div>
        <div className="stat-item">
          <h2>0<span>%</span></h2>
          <p>Leerstand</p>
        </div>
      </div>
      </div>
    </section>
  );
};

export default About;