import React from 'react';
import { useParams } from 'react-router-dom';
import cityDescriptions from '../data/cityText';

const Header = () => {
  const { city } = useParams();

  // Replace dashes with underscores for consistent lookup
  const normalizedCity = city ? city.toLowerCase().replace(/-/g, "_") : 'hessen';
  const cityData = cityDescriptions[normalizedCity] || cityDescriptions['hessen'];

  return (
    <section className="colorful-header">
      <div className="header-wrapper">
        <h1>{cityData.headline}</h1>
        <p>Auf einen Blick</p>
      </div>
      <p className="header-paragraph">{cityData.description}</p>
    </section>
  );
};

export default Header;