import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import LeadForm from './components/LeadForm';
import Form from './components/Form';
import FormHeader from './components/FormHeader';
import ThreeColumns from './components/ThreeColumns';
import ThreeStep from './components/ThreeStep';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ButtonWhite from './components/ButtonWhite';
import ButtonBlue from './components/ButtonBlue';
import ScrollToTop from './components/ScrollToTop';
import Impressum from './components/Impressum';
import AGB from './components/AGB';
import Datenschutz from './components/Datenschutz';
import BlogOverview from './components/BlogOverview';
import Blog from './components/Blog';

import './App.css';
import { articles } from './data/blogArticles';
import { caseStudies } from './data/caseArticles';
import ManagementHero from './components/ManagementHero';
import Mission from './components/Mission';
import Change from './components/Change';
import Advantages from './components/Advantages';
import Header from './components/Header';
import ButtonContact from './components/ButtonContact';
import Why from './components/Why';
import CaseOverview from './components/CaseOverview';
import Case from './components/Case';
import About from './components/About';
import Team from './components/Team';
import Cost from './components/Cost';
import Approach from './components/Approach';
import Owner from './components/Owner';
import Location from './components/Location';
import ThankYou from './components/ThankYou';
import HowItWorks from './components/HowItWorks';
import Services from './components/Services';

function App() {
  // State to track if it's a mobile device
  const [isMobile, setIsMobile] = useState(false);

  // Check for mobile view
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <div className="hero-wrapper">
                  <Navbar />
                  <Hero />
                  {/* Render Form for mobile and LeadForm for desktop */}
                  {isMobile ? <Form /> : <LeadForm />}
                </div>
                <ThreeStep />
                <ButtonBlue />
                <ThreeColumns />
                <HowItWorks />
                <div className="content-wrapper">
                  <FAQ />
                  <ButtonWhite />
                </div>
              </>
            }
          />
          <Route path="/verwaltung" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <About />
                {/* Hide LeadForm if it's a mobile device */}
                {!isMobile && <LeadForm />}              </div>
              <Mission />
              <ButtonBlue />
              <Services />
              <Owner />
              <Team />
              <Cost />
              <div className='hero-wrapper'>
                <Change />
                <ButtonBlue />
              </div>
            </>
          } />
          <Route path="/hausverwaltung" element={
            <>
              <div className="content-wrapper">
                <Navbar />
                <FormHeader />  {/* Ohne city */}
                <div className="form-wrapper">
                  <Form />
                </div>
              </div>
              <Header />
              <Cost />
              <div className='content-wrapper'>
                <FAQ />
              </div>
            </>
          } />

          {/* Dynamische Route für jede Stadt */}
          <Route path="/hausverwaltung/:city" element={
            <>
              <div className="content-wrapper">
                <Navbar />
                <FormHeader />
                <div className="form-wrapper">
                  <Form />
                </div>
              </div>
              <Header />
              <Cost />
              <div className='content-wrapper'>
                <FAQ />
              </div>
            </>
          } />

          {/* Blog Übersicht */}
          <Route path="/blog" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <BlogOverview articles={articles} />
              </div>
            </>
          } />

          {/* Dynamische Route für Blog-Details */}
          <Route path="/blog/:slug" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <Blog articles={articles} />
              </div>
            </>
          } />
          <Route path="/kontakt" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <section className="contact">
                  <div className="contact-content">
                    <h1 className='contact-heading'>Wir sollten für Sie verwalten!</h1>
                    <p className='contact-paragraph'>hamba ist die perfekte Lösung für die Verwaltung Ihres Mehrfamilienhauses. Verschaffen Sie sich selbst einen Eindruck und kontaktieren Sie uns. Wir freuen uns auf Ihre Anfrage!</p>
                  </div>
                  <div className="hero-img">
                    <Form />
                  </div>
                </section>
              </div>
              <Why />
              <Cost />
              <div className='content-wrapper'>
                <FAQ />
              </div>
            </>
          } />
          <Route path="/impressum" element=
            {
              <>
                <div className='hero-wrapper'>
                  <Navbar />
                  <Impressum />
                </div>
              </>

            } />
          <Route path="/agb" element=
            {
              <>
                <div className='hero-wrapper'>
                  <Navbar />
                  <AGB />
                </div>
              </>

            } />
          <Route path="/datenschutz" element=
            {
              <>
                <div className='hero-wrapper'>
                  <Navbar />
                  <Datenschutz />
                </div>
              </>

            } />
          <Route path="/über" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
              </div>
            </>
          } />
          {/*<Route path="/case" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <CaseOverview caseStudies={caseStudies} />
              </div>
            </>
          } />
          <Route path="/case/:slug" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <Case caseStudies={caseStudies} />
              </div>
            </>
          } />*/}
          <Route path="/standort" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <Location />
              </div>
            </>
          } />
          <Route path="/danke" element={
            <>
              <div className='hero-wrapper'>
                <Navbar />
                <ThankYou />
              </div>
            </>
          } />
        </Routes>
        <Footer articles={articles} />
      </div>
    </Router>
  );
}

export default App;

