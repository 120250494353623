import React from 'react';
import { FaCalculator, FaLaptopHouse, FaScrewdriver, FaPhone, FaLongArrowAltUp, FaFileInvoice } from 'react-icons/fa';

const servicesData = [
  {
    title: 'Mietermanagement',
    description: 'Wir übernehmen die Auswahl und intensive Überprüfung neuer Mieter, verwalten laufende Mietverträge und sorgen für effiziente Mieterwechsel.',
    icon: <FaCalculator />,
  },
  {
    title: 'Finanzbuchhaltung',
    description: 'Mit vollständigem Überblick der Objektfinanzen überwachen wir alle Zahlungseingänge und offene Forderungen. Jährliches erstellen der Neben- und Heizkostenabrechnungen.',
    icon: <FaLaptopHouse />,
  },
  {
    title: 'Instandhaltung',
    description: 'Zur Werterhaltung führen wir regelmäßige Wartungen durch und koordinieren alle notwendigen Reparaturen. Dazu gehören Angebotseinholung, Handwerker-beauftragung & Qualitätsüberwachung.',
    icon: <FaFileInvoice />,
  },
  {
    title: 'Rechtliches',
    description: 'Wir sorgen für die Einhaltung aller gesetzlichen Vorschriften und vertreten Sie bei Mietstreitigkeiten. Außerdem prüfen wir alle Mietverträge auf Rechtssicherheit und halten erforderliche Versicherungen aktuell.',
    icon: <FaPhone />,
  },
  {
    title: 'Berichterstattung',
    description: 'Wir informieren Sie regelmäßig über alle wichtigen Vorgänge. Dazu gehören monatliche Berichte der Finanzen, Mieterstruktur und Instandhaltungs-maßnahmen, damit Sie immer im Bilde sind.',
    icon: <FaScrewdriver />,
  },
  {
    title: 'Renditeoptimierung',
    description: 'Zur Rentabilitätssteigerung analysieren wir den Markt und passen die Mieten an. Wertsteigerungen werden durch Modernisierungen erzielt und Leerstände minimiert.',
    icon: <FaLongArrowAltUp />,
  },
];

function Services() {
  return (
    <div className="services-container">
      <h1 className="services-title">Unser Rundum-Sorglos-Paket</h1>
      <h3>Wir übernehmen <a>alles</a> für Sie. Und damit meinen wir alles.</h3>
      <div className="services-grid">
        {servicesData.map((service, index) => (
          <div key={index} className="service-card">
            <div className="service-icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;