import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';

// Beispiel-FAQ-Daten
const faqData = [
    {
        question: 'Was macht eine Hausverwaltung?',
        answer: (
            <div>
                <p>
                    Eine Hausverwaltung wird mit der Verwaltung von vermieteten Mehrfamilienhäusern, ganzen Wohnanlagen, einzelnen Eigentumswohnungen und Gewerbeobjekten beauftragt.</p>
                <p>
                    Auch bekannt als Mietverwaltung oder Wohnungsverwaltung ist die Tätigkeit der Hausverwaltung abzugrenzen von der Verwaltung von Gemeinschaftseigentum einer Wohnungseigentümergemeinschaft (WEG).
                </p>
                <p>
                    Dennoch übernimmt eine Hausverwaltung oft beide Tätigkeiten in einer sogenannten Misch- bzw. Sondereigentumsverwaltung.
                </p>
            </div>
        )
    },
    {
        question: 'Und was macht hamba anders?',
        answer: (
            <div>
                <p>
                    Wir verstehen uns quasi als Eigentümer Ihrer Immobilie. Das heißt, dass wir Ihr Objekt genauso anlegen, verwalten und im Auge behalten wie unsere eigenen.</p>
                    <p>
                    Das hat für Sie den entscheidenden Vorteil, dass Sie sich sicher sein können das Ihr Mehrfamilienhaus optimal und mit viel Fürsorge verwaltet wird.</p>
                    <p>
                    Sobald wir für Sie die Verwaltung übernehmen, kümmern wir uns um alles, da alles andere unserer Philosophie widerspricht.</p>
            </div>
        )
    },
    {
        question: 'Und was genau ist Mietverwaltung?',
        answer: (
            <div>
                <p>
                    Im Rahmen der Mietverwaltung beauftragt ein Eigentümer eine externe Hausverwaltung mit der Verwaltung seiner Wohn- oder Gewerbeimmobilie.
                </p>
                <p>
                    Als bevollmächtigter Vertreter des Eigentümers agiert die Hausverwaltung als Ansprechpartner für Mieter und Dienstleister, überwacht die relevanten Zahlungsströme, schließt Verträge und handelt generell im besten Interesse des Eigentümers.</p>
                <p>
                    Auftretende Probleme werden umgehend und langfristig gelöst. In enger Absprache mit Eigentümern und Mietparteien plant und beaufsichtigt die Hausverwaltung zudem Renovierungs- und Sanierungsarbeiten.
                </p>
            </div>
        )
    },
    {
        question: 'Wie unterscheiden sich die Miet- und die WEG-Verwaltung?',
        answer: (
            <div>
                <p>
                    Die WEG-Verwaltung (Wohneigentumsverwaltung) dient der Verwaltung von gemeinschaftlichem Eigentum mit Bezug zu der entsprechenden Immobilie.</p>
                <p>Mietverwaltung hingegen meint die gänzliche Verwaltung einer Immobilie im Hinblick auf Zahlungsein- und ausgänge, sowie die fortlaufende Instandhaltung der Liegenschaft.</p>
            </div>
        )
    },
    {
        question: 'Wann brauche ich eine Verwaltung?',
        answer: (
            <div>
                <p>
                    Die Beauftragung einer externen Verwaltung kann diverse Gründe haben. Sind Eigentümer nicht in unmittelbarer Nähe oder stark beruflich eingespannt und können wenig Zeit für die Verwaltung aufbringen, lohnt sich oft ein externer Verwalter.</p>
                <p>Die Eigenverwaltung einer Immobilie ist natürlich immer möglich, verlangt aber entsprechenden Zeitaufwand und eine gute Kenntnis der Thematik. Sollten Sie mehrere Immobilien besitzen, die an verschiedenen Standorten liegen empfiehlt sich eine Hausverwaltung. Uns ist es möglich Verwaltungsobjekte regional zu bündeln und Synergieeffekte innerhalb unseres Verwaltungsgebietes zu nutzen.</p>
            </div>
        )
    },
    {
        question: 'Wo verwaltet hamba Mehrfamilienhäuser?',
        answer: (
            <div>
                <p>
                    Aktuell konzentrieren wir uns auf das Rhein-Main-Gebiet mit besonderem Fokus auf Wiesbaden, den Hochtaunuskreis und den Main-Taunus-Kreis. Dabei planen wir langfristig auch weitere Regionen abzudecken.</p>
                <p>Sollte Ihre Immobilie außerhalb unseres derzeitigen Verwaltungsgebiets liegen, zögern Sie bitte nicht sondern <Link to="/kontakt">kontaktieren</Link> Sie uns trotzdem. Gerne prüfen wir ob die Verwaltung möglich ist.</p>
            </div>
        )
    },
    {
        question: 'Was kostet eine Hausverwaltung?',
        answer: (
            <div>
                <p>
                    Die Kosten einer Hausverwaltung variieren abhängig von der entsprechenden Immobilie, sowie dem Leistungsangebot. In der Mietverwaltung sind 30 bis 40 € pro Wohnung und Monat typisch. </p>
                <p>Bei größeren Liegenschaften kann es aufgrund der Anzahl an Wohnungen aber auch pro Einheit günstiger werden. Grundsätzlich ist der veranschlagte Preis, ob pauschal oder als prozentuale Vergütung der Wohnungsmiete auch ein Indikator für die Qualität der Hausverwaltung.</p>
                <p>Teurer heißt nicht unbedingt besser, aber sehr günstig heißt in der Regel auch entsprechender Service. In der WEG-Verwaltung liegt die monatliche Vergütung meist bei 20 bis 30 € pro Wohnung. Bei kleineren Liegenschaften sind die Preise allerdings höher. Dennoch ist auch hier das gebotene Leistungsspektrum indikativ für die Verwaltervergütung.</p>
            </div>
        )
    },
];

const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const contentRefs = useRef([]);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div className="faq">
            <div className="new-main-container">
                <div className="faq-wrapper">
                    <div className="faq-city-title">
                        <div className="wrapper-city-title">
                            <h2 className="faq-heading">Häufig gestellte Fragen</h2>
                        </div>
                        <p>Was macht eine gute Hausverwaltung aus? Vielleicht stellen Sie sich ja wie andere neue Kunden von hamba auch diese Fragen:</p>
                    </div>
                    <div className="tarif-faq-wrapper no-mt">
                        {faqData.map((faq, index) => (
                            <div key={index} className="question-faq">
                                <div className="faq-arrow" onClick={() => toggleFAQ(index)}>
                                    <h5 className="faq-question-heading">{faq.question}</h5>
                                    <img
                                        src="https://cdn.prod.website-files.com/619239d334e8b4e119f9557b/635a86b65f1f0a476db2ce81_Arrow.svg"
                                        loading="lazy"
                                        alt=""
                                        width="7"
                                        className={`arrow-faq-right ${activeIndex === index ? 'active' : ''}`}
                                        style={{
                                            transition: 'transform 0.5s ease',
                                            transform: activeIndex === index ? 'rotate(90deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                </div>
                                <div
                                    className="faq-wrapper-text"
                                    ref={el => contentRefs.current[index] = el}
                                    style={{
                                        maxHeight: activeIndex === index ? `${contentRefs.current[index]?.scrollHeight}px` : '0',
                                        transition: activeIndex === index ? 'max-height 0.3s ease-in' : 'max-height 0.3s ease-out',
                                        overflow: 'hidden',
                                    }}
                                >
                                    <div className="faq-answer">
                                        {faq.answer}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FAQ;