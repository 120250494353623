import React from 'react';

const ThreeColumns = () => {
    return (
        <div className="three-columns">
            <h1 className="three-columns-title">Viele Verwaltungen enttäuschen</h1>
            <h3 className='three-columns-small-title'>Sie verdienen die <a>Richtige.</a></h3>
            <div className="three-columns-container">
                <div className="three-column">
                    <img src="snail.png" loading="lazy" width="220.5"></img>
                    <h3 className="three-column-subtitle">Schlechter Service</h3>
                    <p className="three-column-text">Keine vor Ort Präsenz, keine Kommunikation, keine Abrechnungen, keine Transparenz. Da wären wir als Eigentümer auch frustriert!</p>
                </div>
                <div className="three-column">
                <img src="contract.png" loading="lazy" width="220.5"></img>
                    <h3 className="three-column-subtitle">Unattraktive Modelle</h3>
                    <p className="three-column-text">Langfristige Vertragsbindung bei hohen Gebühren - auch bei Leerstand. Mangelnde Motivation, weil Gebühren ohnehin fließen. Klingt unfair!</p>
                </div>
                <div className="three-column">
                <img src="fax.png" loading="lazy" width="220.5"></img>
                    <h3 className="three-column-subtitle">Veraltete Strukturen</h3>
                    <p className="three-column-text">Viele Verwalter setzen auch heute kaum auf Digitalisierung und sind vollkommen überlastet. So wird guter Service immer und immer schwerer!</p>
                </div>
            </div>
        </div>
    );
};

export default ThreeColumns;

