import React from 'react';

const Advantages = () => {
  const advantagesData = [
    {
      imgSrc: "https://cdn.prod.website-files.com/619239d334e8b4e119f9557b/65ef4eb59029b11084f993fe_Proximite%CC%81.png",
      title: "Reaktionsschnelle",
      description: "Der Verwalter oder unser Expertenteam melden sich innerhalb von 24h bei Ihnen zurück."
    },
    {
      imgSrc: "https://cdn.prod.website-files.com/619239d334e8b4e119f9557b/65ef4eb6e27463493ec2ae38_Re%CC%81activite%CC%81.png",
      title: "Großes Netzwerk",
      description: "Handwerker und Dienstleister in Ihrer Nähe.Handwerker und Dienstleister in Ihrer Nähe."
    },
    {
      imgSrc: "https://cdn.prod.website-files.com/619239d334e8b4e119f9557b/65ef4eb5a73fe457340a3e54_Transparence.png",
      title: "Transparenz",
      description: "Verfolgen Sie die Verwaltung Ihrer WEG in Echtzeit, für volle Transparenz bei allen Themen rund um Ihr Gebäude."
    }
  ];

  return (
    <div className="advantages-container">
      {advantagesData.map((advantage, index) => (
        <div key={index} className="advantage-item">
          <div className="advantage-image">
            <img src={advantage.imgSrc} alt={advantage.title} className="image-icon" />
          </div>
          <div className="advantage-content">
            <h3 className="advantage-title">{advantage.title}</h3>
            <p className="advantage-description">{advantage.description}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Advantages;
