import React from 'react';
import { FaLinkedinIn } from 'react-icons/fa'; // LinkedIn icon

const Team = () => {
  const teamMembers = [
    {
      name: 'Dr. Frank Peter Balz',
      title: 'Geschäftsführer',
      img: 'FPB-Team-p-500.png',
      linkedin: 'https://www.linkedin.com/in/frank-peter-balz/',
    },
    {
      name: 'Felix Balz | IREBS',
      title: 'Prokurist',
      img: 'FB-Team-p-500.png',
      linkedin: 'https://www.linkedin.com/in/felix-balz/',
    },
  ];

  return (
    <section className="team-section">
      <h1 className="team-title">Wer wir sind</h1>
      <h3>Unsere <a>Geschäftsführung</a></h3>

      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member-card">
            <img src={member.img} alt={member.name} className="team-member-image" />
            <h3 className="team-member-name">{member.name}</h3>
            <p className="team-member-title">{member.title}</p>
            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin-icon">
              <FaLinkedinIn />
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Team;