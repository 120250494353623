import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const monthMapping = {
  Januar: '01',
  Februar: '02',
  März: '03',
  April: '04',
  Mai: '05',
  Juni: '06',
  Juli: '07',
  August: '08',
  September: '09',
  Oktober: '10',
  November: '11',
  Dezember: '12',
};

const convertGermanDate = (dateStr) => {
  const [day, monthName, year] = dateStr.split(' ');
  const month = monthMapping[monthName];
  return `${year}-${month}-${day.replace('.', '')}`;
};

const BlogOverview = ({ articles }) => {
  const [visibleArticles, setVisibleArticles] = useState(6);
  const [selectedCategory, setSelectedCategory] = useState('alle');

  const sortedArticles = [...articles].sort(
    (a, b) => new Date(convertGermanDate(b.date)) - new Date(convertGermanDate(a.date))
  );

  const loadMoreArticles = () => {
    setVisibleArticles((prevVisible) => prevVisible + 6);
  };

  const getCategoryClass = (category) => {
    switch (category.toLowerCase()) {
      case 'news':
        return 'category-news';
      case 'vermietung':
        return 'category-vermietung';
      case 'verwaltung':
        return 'category-verwaltung';
      default:
        return '';
    }
  };

  // Helper function to get button class based on category
  const getButtonClass = (category) => {
    switch (category.toLowerCase()) {
      case 'news':
        return 'category-btn-news';
      case 'vermietung':
        return 'category-btn-vermietung';
      case 'verwaltung':
        return 'category-btn-verwaltung';
      default:
        return '';
    }
  };

  const formatDate = (dateString) => {
    const [day, monthName, year] = dateString.split(' ');
    return `${day.replace('.', '')}.${monthMapping[monthName]}.${year}`;
  };

  // Filter articles by the selected category
  const filteredArticles = selectedCategory === 'alle' 
    ? sortedArticles 
    : sortedArticles.filter(article => article.category.toLowerCase() === selectedCategory);

  return (
    <div>
      <div className='blog-wrapper'>
      {/* Category Filter Buttons */}
      <div className="category-filter">
        {['alle', 'news', 'vermietung', 'verwaltung'].map((category) => (
          <button
            key={category}
            className={`category-btn ${getButtonClass(category)} ${selectedCategory === category ? 'active' : ''}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category.charAt(0).toUpperCase() + category.slice(1)}
          </button>
        ))}
      </div>
      {/* Blog Overview */}
      <div className="blog-overview">
        {filteredArticles.slice(0, visibleArticles).map((article) => (
          <div className="blog-card" key={article.id}>
            <Link to={`/blog/${article.slug}`}>
              <img
                src={article.thumbnail}
                alt={article.title}
                className="blog-card-image"
              />
              <div className="blog-card-content">
                <h2>{article.title}</h2>
                <div className="blog-meta">
                  <span className={`blog-category ${getCategoryClass(article.category)}`}>
                    {article.category}
                  </span>
                  <span className="blog-date">{formatDate(article.date)}</span>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      {visibleArticles < filteredArticles.length && (
        <button onClick={loadMoreArticles} className="load-more-btn">
          Mehr
        </button>
      )}
      </div>
    </div>
  );
};

export default BlogOverview;