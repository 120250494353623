// src/components/AGB.js
import React from 'react';

const AGB = () => {
    return (
        <>
            <div className='impressum-container'>
                <h1>Allgemeinen Geschäftsbedingungen</h1>
                <p>Bitte lesen Sie diese Allgemeinen Geschäftsbedingungen ("AGB") sorgfältig durch. Diese AGB sind eine rechtliche Vereinbarung zwischen Ihnen und hamba. Durch die Annahme der AGB erklären Sie sich mit diesen AGB einverstanden. Wenn Sie diese AGB im Namen einer anderen juristischen Person akzeptieren, müssen Sie ordnungsgemäß bevollmächtigt sein, diese Person und die ggf. mit ihr verbundenen Unternehmen als deren bevollmächtigte Vertreter an diese AGB zu binden.</p>


                <h2>1. Zweck und Begriffsbestimmungen</h2>
                <p>
                    1.1 - Zweck der vorliegenden AGB ist es, die Bedingungen für die Nutzung der auf der hamba-Plattform über die folgende Adresse
                    https://www.hambacore.com zugänglichen Website angebotenen Dienste (im Folgenden als „Dienste“ bezeichnet) festzulegen sowie die Rechte und Pflichten der Parteien in diesem Zusammenhang zu bestimmen. Sie können erforderlichenfalls durch besondere Nutzungsbedingungen für bestimmte Dienste ergänzt werden. Im Falle von Widersprüchen haben die besonderen Bedingungen Vorrang vor den vorliegenden AGB.
                    <br></br>
                    <br></br>

                    1.2 - Daneben dienen die AGB dazu, die Bedingungen für die Vermittlungsdienstleistungen von Angeboten für Immobilienverwaltung zu regeln. Zu diesem Zweck speichert und verarbeitet hamba die Kontaktdaten der Eigentümerschaft und der Kontaktperson und gibt diese bei Bedarf an ausgewählte Partner weiter.
                    <br></br>
                    <br></br>

                    1.3 - Begriffsbestimmungen Folgende Begriffe haben für die Zwecke des vorliegenden Vertrags nachstehende Bedeutung:
                    <br></br>
                    <br></br>

                    Nutzer: bezeichnet jede Person, die die Plattform im Sinne der Allgemeinen Nutzungsbedingungen (AGB) nutzt.
                    <br></br>
                    <br></br>
                    Interessent: bezeichnet eine natürliche oder juristische Person, die zu Zwecken der Kontaktaufnahme und Angebotserstellung die personenbezogenen Daten im Kontaktformular auf der Website von hamba hinterlegt.
                    <br></br>
                    <br></br>

                    Inhalt: im Konto der Nutzer auf der Plattform eingefügte Inhalte. Jeder Nutzer bleibt für die in seinem Namen eingefügten Inhalte verantwortlich.
                    <br></br>
                    <br></br>

                    Dienst: bezeichnet die auf der hamba-Plattform beschriebenen Diensten in der Form und mit den Funktionalitäten und technischen Mitteln, mit der sie auf dieser zur Verfügung stehen.
                    <br></br>
                    <br></br>

                    Website: bezeichnet die hamba-Internetseite, die es dem Interessenten oder der Eigentümerschaft ermöglicht, Daten mitzuteilen.
                    <br></br>
                    <br></br>

                    Besucher ist jede voll geschäftsfähige natürliche Person, unabhängig davon, ob sie ein Nutzer der hamba-Dienste ist oder nicht.
                    <br></br>
                    <br></br>

                </p>

                <h2>2. Betreiber der Dienste</h2>
                <p>
                    Die Dienste auf der Website werden von der hamba GmbH, mit Sitz in der Limburger Straße 17, 61462 Königstein im Taunus, vertreten durch Herrn Dr. Frank Peter Balz (im Folgenden: „hamba“) ausgeführt.
                    <br></br>
                    <br></br>
                    hamba kann unter der folgenden Adresse kontaktiert werden:
                    <br></br>
                    <br></br>
                    Postanschrift: Limburger Str. 17, 61462 Königstein im Taunus, E-Mail-Adresse: info@hambacore.com
                </p>

                <h2>3. Zugang zur Website und zu Diensten</h2>
                <p>
                    Die Dienste sind vorbehaltlich der auf der Website vorgesehenen Einschränkungen zugänglich: Für jede natürliche Person, die voll geschäftsfähig ist, um Verpflichtungen nach diesen AGB einzugehen. Eine natürliche Person, die nicht voll geschäftsfähig ist, kann nur mit Zustimmung ihres gesetzlichen Vertreters auf die Website und die Dienste zugreifen;
                    jede juristische Person, die durch eine natürliche Person handelt, die die Rechtsfähigkeit besitzt, im Namen und im Auftrag der juristischen Person Verträge zu schließen.
                </p>
                <h2>4. Annahme der AGB</h2>
                <p>
                    Die Nutzung der Dienste setzt die vollständige und uneingeschränkte Annahme der AGB durch den Nutzer voraus. Diese Annahme kann nur umfassend und vorbehaltlos sein. Der Nutzer, der mit diesen AGB nicht einverstanden ist, darf die Dienste nicht nutzen.
                </p>
                <h2>
                    5.  Kontaktaufnahme zur Angebotsstellung</h2>
                <p>
                    Hinterlässt der Interessent per Kontaktformular auf der hamba-Website seine Kontaktdaten, so kann sich hamba zum Zwecke der Angebotserstellung telefonisch und/oder per E-Mail mit dem Interessenten in Kontakt setzen. Das Stellen der Anfrage ist kostenlos und unverbindlich.
                </p>
                <h2>6. Beschreibung der Dienste</h2>
                <p>Der Nutzer hat Zugang zu den auf der hamba-Plattform beschriebenen Diensten in der Form und mit den Funktionalitäten und technischen Mitteln, mit der sie auf dieser zur Verfügung stehen.</p>
                
                <h2>7. Zugang zu den Diensten</h2>
                <p>Die Dienste sind über das Internet 24 Stunden am Tag, 7 Tage die Woche zugänglich, außer in Fällen höherer Gewalt, bei Ereignissen, die außerhalb der Kontrolle von hamba liegen, bei eventuellen Pannen oder bei Wartungsarbeiten, die für das ordnungsgemäße Funktionieren des Dienstes erforderlich sind. Der Nutzer erkennt an, dass hamba nicht für eine Unterbrechung oder Verzögerung der Dienste haftbar gemacht werden kann und keinerlei Ansprüche auf Entschädigung begründet.

                    hamba verpflichtet sich, alle ihm zur Verfügung stehenden Mittel einzusetzen, um einen qualitativ hochwertigen Zugang zu den Diensten zu gewährleisten.</p>

            

                <h2>8. Verpflichtungen des Nutzers</h2>
                <p>Unbeschadet der anderen in diesen AGB genannten Pflichten verpflichtet sich der Nutzer zur Einhaltung der folgenden Pflichten:
                <br></br>
                <br></br>

                    8.1 - Der Nutzer verpflichtet sich, bei der Nutzung der Dienste die geltenden Gesetze und Vorschriften einzuhalten und nicht gegen die Rechte Dritter oder die öffentliche Ordnung zu verstoßen.
                    <br></br>
                    <br></br>

                    8.2 - Der Nutzer erkennt an, dass er sich auf der Website über die Merkmale und Beschränkungen, insbesondere technischer Art, aller Dienste informiert hat. Er ist allein verantwortlich für seine Nutzung der Dienste.
                    <br></br>
                    <br></br>

                    8.3 - Der Nutzer ist darüber informiert und akzeptiert, dass die Durchführung der Dienste eine Internetverbindung voraussetzt und dass die Qualität der Dienste direkt von dieser Verbindung abhängt, für die er allein verantwortlich ist.
                    <br></br>
                    <br></br>

                    8.4 - Der Nutzer ist auch allein verantwortlich für die Beziehungen, die er mit anderen Nutzern eingeht, und für die Informationen, die er ihnen im Rahmen der Dienste mitteilt. Es liegt in der Verantwortung des Nutzers, bei diesen Beziehungen und Kommunikationen angemessene Vorsicht und Umsicht walten zu lassen. Der Nutzer verpflichtet sich außerdem, im Umgang mit anderen Nutzern die üblichen Regeln der Höflichkeit zu beachten.
                    <br></br>
                    <br></br>

                    8.5 - Der Nutzer verpflichtet sich, die Dienste ausschließlich persönlich zu nutzen. Folglich ist es dem Nutzer nicht gestattet, seine Rechte oder Pflichten aus diesem Vertrag ganz oder teilweise an Dritte abzutreten, zu gewähren oder zu übertragen, in welcher Form auch immer.
                    <br></br>
                    <br></br>

                    8.6 - Der Nutzer verpflichtet sich, hamba alle Informationen zur Verfügung zu stellen, die für die ordnungsgemäße Erbringung der Dienstleistungen erforderlich sind. Ganz allgemein verpflichtet sich der Nutzer, aktiv mit hamba zusammenzuarbeiten, um die ordnungsgemäße Durchführung des vorliegenden Vertrags zu gewährleisten.
                    <br></br>
                    <br></br>

                    8.7 - Der Nutzer verpflichtet sich, dem Unternehmen korrekte Informationen über sich selbst zu geben.
                    <br></br>
                    <br></br>

                    8.8 - Der Nutzer erkennt an, dass die Dienste ihm eine zusätzliche, aber keine alternative Lösung zu den Mitteln bieten, die er bereits verwendet, um das gleiche Ziel zu erreichen, und dass diese Lösung kein Ersatz für diese anderen Mittel ist.
                    <br></br>
                    <br></br>

                    8.9 - Der Nutzer muss die notwendigen Maßnahmen ergreifen, um die von ihm als notwendig erachteten Informationen zu speichern, wovon – unbeschadet der Geltendmachung von Betroffenenanfragen gemäß der DSGVO - keine Kopie zur Verfügung gestellt wird.Unbeschadet der anderen in diesen AGB genannten Pflichten verpflichtet sich der Nutzer zur Einhaltung der folgenden Pflichten:</p>

                <h2>9. Haftung des Nutzers</h2>
                <p>Der Nutzer haftet hamba gegenüber für alle Beschwerden, Ansprüche, Klagen und/oder Forderungen, die dem Unternehmen durch die Verletzung einer seiner Verpflichtungen oder Garantien im Rahmen dieser AGB durch den Nutzer entstehen können.

                    Der Nutzer verpflichtet sich, hamba für alle Schäden zu entschädigen, die es erleiden könnte, und alle Kosten, Gebühren und/oder Urteile zu zahlen, die es möglicherweise infolgedessen zu tragen hat.</p>

                <h2>10. Verbotenes Verhalten</h2>
                <p>10.1 - Es ist strengstens verboten, die Dienste für folgende Zwecke zu nutzen:
                <br></br>
                <br></br>


                    Die Durchführung von illegalen oder betrügerischen Aktivitäten oder von Aktivitäten, die die Rechte oder die Sicherheit Dritter verletzen,
                    Störung der öffentlichen Ordnung oder Verstoß gegen geltende Gesetze und Vorschriften,
                    das Eindringen in das Computersystem eines Dritten oder jegliche Aktivität, die darauf abzielt, diesen zu schädigen oder zu kontrollieren,
                    Eingriffe in das Computersystem eines Dritten oder Teile davon, die dessen Integrität oder Sicherheit verletzen,
                    das Versenden von unaufgeforderten E-Mails und/oder die kommerzielle Anwerbung oder Werbung,
                    Manipulationen zur Verbesserung der Referenzierung einer fremden Website,
                    Beihilfe zu einer oder mehreren der oben beschriebenen Handlungen und Aktivitäten, gleich in welcher Form oder Weise,
                    und ganz allgemein alle Praktiken, die die Dienste für andere Zwecke als die, für die sie konzipiert wurden, missbrauchen.
                    <br></br>
                    <br></br>
                    
                    10.2 - Den Nutzern ist es strengstens untersagt, das Konzept, die Technologien oder jedes andere Element der Website und der Dienste von hamba für eigene Zwecke oder die von Dritten zu kopieren und/oder umzuleiten.
                    <br></br>
                    <br></br>
                    ‍
                    10.3 - Streng verboten sind auch (i) jegliches Verhalten, das geeignet ist, die Kontinuität der Dienste zu unterbrechen, auszusetzen, zu verlangsamen oder zu verhindern, (ii) jegliches Eindringen oder versuchtes Eindringen in die Systeme von hamba, (iii) jegliche Zweckentfremdung der Systemressourcen der Website, (iv) jegliche Handlungen, die geeignet sind, die Infrastruktur der Website und der Dienste unverhältnismäßig zu belasten, (v) jegliche Verstöße gegen Sicherheits- und Authentifizierungsmaßnahmen, (vi) Handlungen, die geeignet sind, die finanziellen, kommerziellen oder moralischen Rechte und Interessen von hamba oder der Nutzer seiner Website und der Dienste zu verletzen, (vii) Anlegung eines Nutzerkontos unter falschem Namen, Identitätsdiebstahl, Anlegung eines Nutzerkontos mit falschen Kontaktdaten oder unter Angabe eines falschen beruflichen Status und schließlich ganz allgemein (vii) jeder Verstoß gegen die vorliegenden AGB.
                    <br></br>
                    <br></br>
                    ‍
                    10.4 - Es ist strengstens untersagt, den Zugang zu den Diensten oder zur Website sowie zu den dort gespeicherten und/oder geteilten Informationen ganz oder teilweise zu monetarisieren, zu verkaufen oder zu übermitteln.</p>

                <h2>11. Sanktionen bei Verstößen</h2>
                <p>Im Falle eines Verstoßes gegen eine der Bestimmungen der vorliegenden AGB oder allgemeiner gegen die geltenden Gesetze und Vorschriften durch einen Nutzer behält sich hamba das Recht vor, alle geeigneten Maßnahmen zu ergreifen und insbesondere:
                    <br></br>
                    <br></br>
                    ‍
                    (i) den Zugang zu den Diensten des Nutzers, der der Urheber des Verstoßes oder der Rechtsverletzung ist oder daran beteiligt war, auszusetzen oder zu beenden,
                    <br></br>

                    (ii) alle auf der Plattform veröffentlichten Inhalte zu löschen,
                    <br></br>

                    (iii) auf der Plattform Meldungen zu veröffentlichen, die das Unternehmen für nützlich hält,
                    <br></br>

                    (iv) alle zuständigen Behörden zu benachrichtigen,
                    <br></br>

                    (v) rechtliche Schritte einzuleiten.</p>

                <h2>12. Haftung und Garantie von hamba</h2>
                <p>12.1 - hamba verpflichtet sich, die Dienstleistungen mit Sorgfalt und nach dem Stand der Technik zu erbringen, wobei hamba keine Pflicht zur Erbringung eines bestimmten Ergebnisses trifft, was der Nutzer ausdrücklich anerkennt und akzeptiert.
                <br></br>
                <br></br>

                    12.2 - hamba verpflichtet sich, regelmäßige Kontrollen durchzuführen, um den Betrieb und die Verfügbarkeit der Anwendung zu überprüfen. In diesem Zusammenhang behält sich hamba das Recht vor, den Zugang zur Anwendung zu Wartungszwecken vorübergehend zu unterbrechen. Ebenso kann hamba nicht für Schwierigkeiten oder die vorübergehende Unmöglichkeit des Zugriffs auf die Anwendung aufgrund von Umständen, die außerhalb seiner Kontrolle liegen, höherer Gewalt oder aufgrund von Störungen der Telekommunikationsnetze verantwortlich gemacht werden.
                    <br></br>
                    <br></br>

                    12.3 - hamba garantiert den Nutzern nicht, (i) dass die Dienste, die einer ständigen Überprüfung zur Verbesserung ihrer Leistung und ihres Fortschritts unterzogen werden, völlig frei von Fehlern, Mängeln oder Störungen sind, (ii) dass die Dienste, die Standarddienste sind und nicht spezifisch zum Nutzen eines bestimmten Nutzers nach seinen eigenen persönlichen Bedingungen angeboten werden, speziell seinen Bedürfnissen und Erwartungen entsprechen.
                    <br></br>
                    <br></br>

                    12.4 - hamba haftet gegenüber dem Nutzer für die von ihm sowie seinen gesetzlichen Vertretern oder Erfüllungsgehilfen vorsätzlich oder grob fahrlässig verursachten Schäden,
                    für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit, die hamba, seine gesetzlichen Vertreter oder Erfüllungsgehilfen zu vertreten haben.
                    <br></br>
                    <br></br>

                    12.5 - hamba haftet bei leichter Fahrlässigkeit nicht, außer soweit eine wesentliche Vertragspflicht verletzt wurde, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht oder deren Verletzung die Erreichung des Vertragszwecks gefährdet und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf.</p>

                <h2>13. Geistiges Eigentum</h2>
                <p>Die Systeme, Software, Strukturen, Infrastrukturen, Datenbanken und Inhalte jeglicher Art (Texte, Bilder, Grafiken, Musik, Logos, Marken, Datenbanken usw.), die von hamba auf der Website verwendet werden, sind durch alle geltenden Rechte am geistigen Eigentum oder die Rechte der Datenbankhersteller weltweit geschützt. hamba garantiert, dass es Inhaber der Rechte an geistigem Eigentum ist, die es ihm ermöglichen, die vorliegenden AGB abzuschließen, und dass die hamba Anwendung nicht die Rechte Dritter verletzen kann.

                    Der Abonnent verpflichtet sich, dem Unternehmen jeden ihm bekannten Verstoß hinsichtlich der „hamba-Lösung“ unverzüglich mitzuteilen, und es steht hamba dann frei, die ihm angemessen erscheinenden Maßnahmen zu ergreifen.

                    Der Zugang zum Dienst und das den Nutzern eingeräumte Nutzungsrecht führen nicht zu einer Übertragung von Rechten gleich welcher Art zugunsten des Nutzers. Jede Demontage, Dekompilierung, Entschlüsselung, Extraktion, Wiederverwendung, Vervielfältigung und ganz allgemein jede Reproduktion, Darstellung, Verbreitung und Verwendung eines dieser Elemente, ganz oder teilweise, ohne die Genehmigung von hamba, ist strengstens untersagt und kann gerichtlich verfolgt werden.</p>

                <h2>14. Personenbezogene Daten</h2>
                <p>14.1 - hamba verarbeitet personenbezogene Daten des Nutzers im Auftrag im Rahmen der Kontaktaufnahme und Angebotsstellung. 
                <br></br>
                <br></br>

                    14.2 - Die personenbezogenen Daten des Interessenten (Angaben zur Anfrage und Kontaktdaten) werden von hamba erhoben, gespeichert, verarbeitet und bei Bedarf nach Rücksprache mit dem Kunden an ausgewählte Partner weitergeleitet. Eine Weitergabe der Daten an sonstige Dritte ist ausgeschlossen.
                    <br></br>
                    <br></br>

                    14.3 - Die Erhebung, Verarbeitung und Speicherung von Daten erfolgt ausschließlich nach den Vorgaben des deutschen Datenschutzrechts sowie der EU-Datenschutz-Grundverordnung. Die Datenschutzerklärung finden Sie
                    <a href='/datenschutz'> hier.</a></p>

                <h2>15. Links und Websites von Dritten</h2>
                <p>hamba haftet in keiner Weise für die technische Verfügbarkeit von Websites oder mobilen Anwendungen, die von Dritten (einschließlich seiner möglichen Partner) betrieben werden und zu denen der Nutzer über die Website Zugang hat.

                    hamba übernimmt keine Verantwortung für den Inhalt, die Werbung, die Produkte und/oder die Dienstleistungen, die auf solchen Websites und mobilen Anwendungen Dritter verfügbar sind, die ihren eigenen Nutzungsbedingungen unterliegen.

                    hamba haftet auch nicht für die Transaktionen zwischen dem Nutzer und Dritten (einschließlich ihrer möglichen Partner), an die der Nutzer über die Website verwiesen werden kann, und ist nicht an Streitigkeiten mit diesen Dritten beteiligt, die insbesondere die Lieferung von Produkten und/oder Dienstleistungen, Garantien, Erklärungen und alle anderen Verpflichtungen betreffen, an die diese Dritten gebunden sind.</p>

                <h2>16. Änderungen</h2>
                <p>hamba kann die vorliegenden AGB anpassen, um gesetzliche oder regulatorische Anforderungen oder Anpassungen der Dienste oder geänderte Sicherheitsstandards darzustellen. Der Nutzer wird von hamba mindestens dreißig (30) Tage vor Inkrafttreten der neuen AGB per E-Mail über solche Änderungen informiert.

                    Widerspricht der Nutzer der neuen Fassung nicht innerhalb dieser Frist per E-Mail an die oben angegebene Adresse, gelten die neuen AGB als angenommen. hamba verpflichtet sich, den Nutzer in der Mitteilung über die beabsichtigten Änderungen auf diese Bedeutung seines Verhaltens besonders hinzuweisen. Widerspricht der Nutzer den neuen AGB, behält sich hamba ein Recht zur außerordentlichen Kündigung vor. Die außerordentliche Kündigung durch hamba führt zur Löschung des Nutzerkontos.</p>

                <h2>17. Anwendbares Recht und Gerichtsstand</h2>
                <p>Die vorliegenden allgemeinen Bedingungen unterliegen dem deutschen Recht. Der Gerichtsstand ist Bad Homburg v.d.H.</p>
            </div>
        </>
    );
};

export default AGB;
