import React from 'react';

const ButtonContact = () => {
    // Funktion zum Scrollen zum Anfang der Seite
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: für einen glatten Übergang
        });
    };

    return (
        <div className="button-blue-container" onClick={handleScrollToTop}>
                <button className="custom-blue-button">
                    Kostenloses Angebot anfordern
                </button>
        </div>
    );
};

export default ButtonContact;
