import React from 'react';

const Mission = () => {
  return (
    <section className="mission">
      <div className="mission-content">
        <h1 className="mission-heading">Ihre Immobilie verwaltet wie unsere</h1>
        <h3>Keine <a>Abkürzungen.</a> Keine falschen Versprechen.</h3>
        <p className="mission-paragraph">
          Bei uns stehen Sie im Mittelpunkt. Alles ist bei uns darauf ausgerichtet Ihr Vermögen mit der gleichen Sorgfalt zu betreuen wie unser eigenes. Überzeugen Sie sich selbst.
        </p>
      </div>
      <div className="mission-image">
      <img src="heart.png" alt=""></img>
      </div>
    </section>
  );
};

export default Mission;
